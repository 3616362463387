import React, { useEffect, useMemo, useRef, useState } from 'react'
import './Product.scss'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useStateValue } from '../../store/state';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import Search from '../../assets/images/MyProduct/Search.png'
import Favourite from '../../assets/images/MyProduct/favorite_border.png'
import FavouriteActive from '../../assets/images/MyProduct/favorite_border_active.png'
import bookmark from '../../assets/images/MyProduct/bookmark_border.png'
import bookmarkActive from '../../assets/images/MyProduct/bookmark_border_active.png'
import Vector from '../../assets/images/MyProduct/Vector.png'
import VectorActive from '../../assets/images/MyProduct/Vector_active.png'
import shoppingBag from '../../assets/images/MyProduct/shopping_bag.png';
import MyReminder_icon from '../../assets/images/MyProduct/MyReminder_icon.png';
import active_MyReminder_icon from '../../assets/images/MyProduct/active_MyReminder_icon.png';
import shoppingBagActive from '../../assets/images/MyProduct/shopping_bag_active.png';
import MyProductSidebar from '../../components/Skeleton/MyProductSidebar/MyProductSidebar'
import FilterList from '../../assets/images/catalog/Mobile/FilterList.svg'
import { GENERALFILTER, GETPRODUCTPLP, PRODUCTLEFTFILTER } from '../../store/PLP/Action'
import Gray_Filter from '../../assets/images/purchase/Gray_Filter.png';

const ProductLeftContent = ({ currentSelection, setCurrentSelection, setPLPData, initialLoad, setInitialLoad, loader, setLoader, type, activeSec, daysSelect, typeSelect, setDaysSelect, setTypeSelect, searchQuery }) => {

    const [{ PRODUCTPLPDATA, PRODUCTPLPDATA_FILTER, GENERAL_FILTER_DATA }, dispatch] = useStateValue()
    const { t } = useTranslation()
    const [refreshAPI, setRefreshAPI] = useState(false)
    // const [loader, setLoader] = useState(false)
    // const [currentSelection, setCurrentSelection] = useState({})
    // const [daysSelect, setDaysSelect] = useState(null);
    // const [typeSelect, setTypeSelect] = useState(null);
    const [variety, setVariety] = useState([])
    const [currentVariety, setCurrentVariety] = useState("")
    const [A1, setA1] = useState([])
    const [currentA1, setCurrentA1] = useState("")
    const [A2, setA2] = useState([])
    const [currentA2, setCurrentA2] = useState("")
    const [A3, setA3] = useState([])
    const [currentA3, setCurrentA3] = useState("")
    const [autoLoader, setAutoLoader] = useState(1)
    const [purchasedSelection, setPurchasedSelection] = useState("")
    const [mobileCategory, setMobileCategory] = useState("")
    const [applyFilter, setApplyFilter] = useState(false)
    const [activeSection, setActiveSection] = useState('');
    const history = useHistory();

    useEffect(() => {
        setActiveSection(activeSec);
    }, [activeSec]);

    // Image mapping for active and inactive states in sidebar_1
    const images = {
        MyProduct: { inactive: shoppingBag, active: shoppingBagActive },
        MyPurchase: { inactive: Vector, active: VectorActive },
        MyWishlist: { inactive: bookmark, active: bookmarkActive },
        MyReminder: { inactive: MyReminder_icon, active: active_MyReminder_icon },
        MyFavourite: { inactive: Favourite, active: FavouriteActive },
    };

    useEffect(() => {
        const mobileCategoryId = mobileCategory?.id;
        if (window.innerWidth > 912) return
        setCurrentSelection({ ID: mobileCategoryId })
    }, [mobileCategory])

    useEffect(() => {
        setPLPData([]);
        // setLoader(true);
        setInitialLoad(false);
    }, [daysSelect, currentSelection])

    // Get Product API
    useEffect(() => {
        if (loader) return
        let pageNumber = autoLoader
        if (purchasedSelection != daysSelect) {
            pageNumber = 1
        }
        setPurchasedSelection(daysSelect)
        let TOKEN = localStorage.getItem('token')
        let COMPANYID = localStorage.getItem('companyid')
        let USERID = localStorage.getItem('userid')
        let STOREID = localStorage.getItem('storeid')
        let ID = currentSelection?.ID !== undefined ? currentSelection?.ID : ""
        let SUBID = currentSelection?.subID !== undefined ? currentSelection?.subID : ""
        // if (window.innerWidth <= 912 && ID == "") return
        if (initialLoad && window.innerWidth <= 912 && applyFilter) {
            GETPRODUCTPLP(dispatch, TOKEN, COMPANYID, USERID, STOREID, ID, SUBID, currentVariety, currentA1, currentA2, currentA3, setLoader, pageNumber, variety, A1, A2, A3, daysSelect, searchQuery, typeSelect)
            setApplyFilter(false)
        } else if (!initialLoad) {
            GETPRODUCTPLP(dispatch, TOKEN, COMPANYID, USERID, STOREID, ID, SUBID, currentVariety, currentA1, currentA2, currentA3, setLoader, pageNumber, variety, A1, A2, A3, daysSelect, searchQuery, typeSelect)
            if (window.innerWidth <= 912) {
                setApplyFilter(false);
                // setInitialLoad(true)
            }
        }
    }, [localStorage.getItem('storeid'), currentSelection, variety, currentVariety, currentA1, currentA2, currentA3, refreshAPI, autoLoader, applyFilter, daysSelect])

    // Get category API
    useEffect(() => {
        setCurrentSelection({})
        let COMPANYID = localStorage.getItem('companyid')
        let USERID = localStorage.getItem('userid')
        let STOREID = localStorage.getItem('storeid')
        PRODUCTLEFTFILTER(dispatch, COMPANYID, USERID, STOREID, daysSelect)
    }, [localStorage.getItem('storeid')])



    // active and inactive states in sidebar_1
    const handleItemClick = (section) => {
        setActiveSection(section);
        setPLPData([]);
        switch (section) {
            case "MyProduct":
                history.push('/myproduct');
                break;
            case "MyPurchase":
                history.push('/mypurchase');
                break;
            case "MyReminder":
                history.push('/myreminder');
                break;
            case "MyFavourite":
                history.push('/myfavourite');
                break;
            default:
                break;
        }
    }

    const resetFilter = () => {
        setCurrentVariety("")
        setVariety([])
        setCurrentA1("")
        setA1([])
        setCurrentA2("")
        setA2([])
        setCurrentA3("")
        setA3([])
        setCurrentSelection(prevState => ({
            ID: prevState.ID,
            subID: ""
        }))
    }

    const LEFTFILTERMEMO = useMemo(() => {
        return <>
            {window.innerWidth > 912 ?
                <Accordion>
                    <div className='category-heading'>{t("category")}</div>
                    <button
                        // className='all_category'
                        className={`all_category ${(currentSelection?.ID === "" && currentSelection?.subID === "") || Object.keys(currentSelection)?.length === 0 ? "selectedFilter" : ""}`}
                        onClick={() => setCurrentSelection({ ID: "", subID: "" })}>
                        {t('All')}
                    </button>
                    {PRODUCTPLPDATA_FILTER?.length ? (
                        PRODUCTPLPDATA_FILTER.map((data, i) => (
                            <Accordion.Item eventKey={i} key={i}>
                                <Accordion.Header className={`${currentSelection?.id === data?.id && "selectedFilter"}`}>
                                    {t(data?.name)}
                                </Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        {data?.children_data?.map(childdata => (
                                            <li
                                                className={`${currentSelection?.subID === childdata?.id && "selectedFilter"}`}
                                                onClick={() => setCurrentSelection({ ID: data?.id, subID: childdata?.id })}
                                                key={childdata?.id}
                                            >
                                                {t(childdata?.name)}
                                            </li>
                                        ))}
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))
                    ) : (
                        [...Array(5)].map((_, index) => <MyProductSidebar key={index} />)
                    )}

                </Accordion>
                :
                null
            }
        </>
    }, [PRODUCTPLPDATA_FILTER, currentSelection, mobileCategory, GENERAL_FILTER_DATA, variety, A1, A2, A3, t])

    const handleSelection = (days, type) => {
        setDaysSelect(days);
        setTypeSelect(type);
    };

    const MOBILELEFTFILTERMEMO = useMemo(() => {
        return <>
            {
                window.innerWidth > 912 ?
                    null
                    :
                    <div className='PRODUCTPLP__MOBILE__SIDEBAR'>
                        <span data-bs-toggle="offcanvas" data-bs-target="#offcanvasBottom" aria-controls="offcanvasBottom">
                            <img src={Gray_Filter} alt="" />
                        </span>
                        {activeSec === "MyPurchase" &&
                            <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                                <div class="offcanvas-header">
                                    <button type="button" class="text-reset" data-bs-dismiss="offcanvas"> <span className='fas fa-angle-left'></span> &nbsp; Back</button>
                                    <div>{t('Last Purchase')}</div>
                                </div>
                                <div class="offcanvas-body small">
                                    <ul className={`clickable ${daysSelect === null && typeSelect === null ? "active" : ""}`}
                                        onClick={() => {
                                            handleSelection(null, null)
                                        }}>{t("All")}</ul>
                                    <ul className={`clickable ${daysSelect === "7" && typeSelect === "W" ? "active" : ""}`}
                                        onClick={() => {
                                            handleSelection("7", "W")
                                        }}>{t('Within 1 Week')}</ul>
                                    <ul className={`clickable ${daysSelect === "30" && typeSelect === "W" ? "active" : ""}`}
                                        onClick={() => {
                                            handleSelection("30", "W")
                                        }}>{t('Within 1 Month')}</ul>
                                    <ul className={`clickable ${daysSelect === "31" && typeSelect === "O" ? "active" : ""}`}
                                        onClick={() => {
                                            handleSelection("31", "O")
                                        }}>{t('Over 1 Month')}</ul>
                                    <ul className={`clickable ${daysSelect === "90" && typeSelect === "O" ? "active" : ""}`}
                                        onClick={() => {
                                            handleSelection("90", "O")
                                        }}>{t('Over 3 Month')}</ul>
                                    <div className='offcanvas__buttons' style={{ width: "95%", marginLeft: "10px" }}                                    >
                                        <button style={{ width: "100%", borderRadius: "4px" }} onClick={() => setApplyFilter(true)} className='offcanvas__apply' data-bs-dismiss="offcanvas">Apply</button>
                                    </div>
                                </div>
                            </div>
                        }

                        {/* <div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
                            <div class="offcanvas-header">
                                <h5 class="offcanvas-title" id="offcanvasBottomLabel">Filter By {mobileCategory?.name}</h5>
                                <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div class="offcanvas-body small">
                                <ul>
                                    {mobileCategory?.children_data?.map(childdata => (
                                        <li
                                            className={`${currentSelection?.subID === childdata?.id && "selectedFilter"}`}
                                            onClick={() => setCurrentSelection({ ID: mobileCategory?.id, subID: childdata?.id })}
                                            // onClick={() => setMobileTwo({ ID: mobileCategory?.id, subID: childdata?.id })}
                                            key={childdata?.id}>
                                            {t(childdata?.name)}
                                        </li>
                                    ))}
                                </ul>
                                {variety?.length ? <hr /> : ""}
                                <ul>
                                    {variety?.length ? variety?.map((data, i) => (
                                        <li
                                            onClick={() => {
                                                setA1(data?.children_data);
                                                setCurrentA1(data?.id);
                                            }} key={i}
                                            className={`${currentA1 == data?.id && "selected"}`}
                                        >{t(data?.name)}</li>
                                    )) : null}
                                </ul>
                                {A1?.length ? <hr /> : ""}
                                <ul>
                                    {A1?.length ? A1?.map((data, i) => (
                                        <li
                                            onClick={() => {
                                                setA2(data?.children_data)
                                                setCurrentA2(data?.id)
                                            }} key={i}
                                            className={`${currentA2 == data?.id && "selected"}`}
                                        >{t(data?.name)}</li>
                                    )) : null}
                                </ul>
                                {A2?.length ? <hr /> : ""}
                                <ul>
                                    {A2?.length ? A2?.map((data, i) => (
                                        <li
                                            onClick={() => {
                                                setA3(data?.children_data)
                                                setCurrentA3(data?.id)
                                            }} key={i}
                                            className={`${currentA3 == data?.id && "selected"}`}
                                        >{t(data?.name)}</li>
                                    )) : null}
                                </ul>
                                {A3?.length ? <hr /> : ""}
                                <ul>
                                    {A3?.length ? A3?.map((data, i) => (
                                        <li key={i}>{t(data?.name)}</li>
                                    )) : null}
                                </ul>
                                <div className='offcanvas__buttons'>
                                    <button onClick={resetFilter} className='offcanvas__reset'>Reset</button>
                                    <button onClick={() => setApplyFilter(true)} className='offcanvas__apply' data-bs-dismiss="offcanvas">Apply</button>
                                </div>
                            </div>
                        </div> */}

                        <div className='PRODUCTPLP__MENU'>
                            <span className={`${mobileCategory === "" && "selectedMobileMenu"}`}
                                onClick={() => { setMobileCategory(""); setCurrentSelection({ ID: "", subID: "" }); setDaysSelect(null); setTypeSelect(null); }}>
                                {t('All')}
                            </span>
                            {PRODUCTPLPDATA_FILTER?.map((el, i) => (
                                <span className={`${mobileCategory.id === el.id && "selectedMobileMenu"}`} onClick={() => setMobileCategory(el)} key={i}>{t(el?.name)}</span>
                            ))}
                        </div>
                    </div >
            }
        </>
    }, [PRODUCTPLPDATA_FILTER, currentSelection, mobileCategory, GENERAL_FILTER_DATA, variety, A1, A2, A3, t, daysSelect])

    useEffect(() => {
        console.log("Rendering");
    }, [daysSelect]);


    return (
        <>
            {type === "web" ?
                <>
                    <div className="my_product_sidebar">
                        <div className='sidebar_1'>
                            <div onClick={() => handleItemClick('MyProduct')} className={activeSection === 'MyProduct' ? 'active' : ''}>
                                <span>
                                    <img className='same_size' src={activeSection === 'MyProduct' ? images.MyProduct.active : images.MyProduct.inactive} />
                                </span>
                                <span>
                                    {t("My Product")}
                                </span>
                            </div>
                            <div onClick={() => handleItemClick('MyPurchase')} className={activeSection === 'MyPurchase' ? 'active' : ''}>
                                <span>
                                    <img className='diff_size' src={activeSection === 'MyPurchase' ? images.MyPurchase.active : images.MyPurchase.inactive} />
                                </span>
                                <span>
                                    {t("My Purchase")}
                                </span>
                            </div>
                            {/* <div onClick={() => handleItemClick('MyWishlist')} className={activeSection === 'MyWishlist' ? 'active' : ''}>
                                <span>
                                    <img src={activeSection === 'MyWishlist' ? images.MyWishlist.active : images.MyWishlist.inactive} />
                                </span>
                                {t("My Wishlist")}
                            </div> */}
                            <div onClick={() => handleItemClick('MyReminder')} className={activeSection === 'MyReminder' ? 'active' : ''}>
                                <span>
                                    <img className='same_size' src={activeSection === 'MyReminder' ? images.MyReminder.active : images.MyReminder.inactive} />
                                </span>
                                <span>
                                    {t("My Reminder")}
                                </span>
                            </div>
                            <div onClick={() => handleItemClick('MyFavourite')} className={activeSection === 'MyFavourite' ? 'active' : ''}>
                                <span>
                                    <img className='same_size' src={activeSection === 'MyFavourite' ? images.MyFavourite.active : images.MyFavourite.inactive} />
                                </span>
                                <span>
                                    {t("My Favourite")}
                                </span>
                            </div>
                        </div>
                        {
                            (activeSection === 'MyProduct' || activeSection === 'MyPurchase') &&
                            <>
                                <hr></hr>
                                <div className='PRODUCTPLP__SIDEBAR'>
                                    {LEFTFILTERMEMO}
                                </div>
                            </>
                        }
                    </div>
                </> : null
            }
            {type === "mobile" ?
                <>
                    <div className='PRODUCTPLP__SIDEBAR'>
                        {MOBILELEFTFILTERMEMO}
                    </div>
                </> : null
            }
        </>
    );
};

export default ProductLeftContent;