import React, { useCallback, useEffect, useRef, useState } from 'react'
import './StockProductDateCards.scss'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import { useStateValue } from '../../../../../store/state'

function StockProductDateCards(props) {
  const { DD } = props.data
  const { branchAddresses, defaultBranchId } = props
  const { OrderTakingSelection, index, OTSelection, currentOrder } = props
  const { t } = useTranslation()
  const [{ cal, ERP__HOLIDAYS__LIST }, dispatch] = useStateValue();

  const getDayName = (day) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[day];
  }

  const isDisabledDate = (date) => {
    const preferredDay = branchAddresses?.find((el) => el?.branch_id == defaultBranchId);
    // If preferredDay is undefined or null, the date should not be disabled
    if (!preferredDay) {
      return false;
    }

    const day = date.getDay();
    const dayName = getDayName(day);

    // Check if the day should be disabled based on the provided availability
    const isDayDisabled = preferredDay[dayName] === "Y";
    
    // Check if the date is a holiday
    const isHoliday = ERP__HOLIDAYS__LIST.some((holiday) => {
      const holidayDate = new Date(holiday.HOLIDAY_DATE);
      return holidayDate.toDateString() === date.toDateString();
    });


    // Disable the date if it meets any of the specified conditions
    if (
      (!isDayDisabled && !isHoliday) ||
      (isDayDisabled && isHoliday)
    ) {
      return false;
    }
    if (isHoliday) {
      return isHoliday
    }
    if (isDayDisabled) {
      return isDayDisabled
    }
  };

  return (
    <div className={`ORDERTAKING__DATES`}>
      <div className={`ORDERINGCARDSS ${OTSelection === index ? "SELECTEDORDER" : ""} ${!isDisabledDate(new Date(DD)) ? "disabled" : ""} `}
        onClick={() => OrderTakingSelection(index)}>
        <div className='ORDERINGCARDS__DATES'>{moment(DD).format("D MMM YY, ddd")}</div>
      </div>
    </div>

  )
}

export default StockProductDateCards