import axios from "axios";
import request from "../../request";
import { browserName, browserVersion } from "react-device-detect";

export const CHECKTERMS = (dispatch, userid, history, setShow) => {
  let TOKEN = localStorage.getItem('token')
  async function checkTerms() {
    if (userid !== null) {
      try {
        const data = await axios({
          method: 'get',
          url: `${request?.getTerms}${userid}`,
          headers: {
            "Authorization": `Bearer ${TOKEN}`,
          },
        });
        if (data?.data[0]?.termsConditionStatus == "0") {
          setShow(true);
          // console.log(data?.data, 'DATA1234')
        } else {
          history.push("/");
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  checkTerms();
}

export const AGREETERMS = (history, browserName, browserVersion) => {
  let TOKEN = localStorage.getItem('token')
  async function agreeterms() {
    try {
      await axios({
        method: 'post',
        url: `${request?.setTerms}${localStorage.getItem('userid')}`,
        headers: {
          "Authorization": `Bearer ${TOKEN}`,
        },
      })
      AGREETERMSLOG(browserName, browserVersion)
      history.push("/")
    } catch (e) {
      console.log(e)
    }
  }
  agreeterms()
}

export const AGREETERMSLOG = (browserName, browserVersion) => {
  let TOKEN = localStorage.getItem('token')
  let customercode = JSON.parse(localStorage.getItem("userdata"));
  async function agreetermslog() {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      await axios({
        method: "post",
        url: request.logTerms,
        headers: {
          "Authorization": `Bearer ${TOKEN}`,
        },
        data: {
          data: {
            customer_id: localStorage.getItem("userid"),
            company_id: localStorage.getItem("companyid"),
            customer_code: customercode?.extension_attributes?.customer_code,
            remote_ip: res.data.IPv4,
            user_agent: `${browserName} Version:${browserVersion}`,
            action: "accept the terms & condition",
            action_type: "save",
            functioncall_url: "user/save/",
          }
        }
      })
    } catch(e) {
      console.log(e)
    }
  }
  agreetermslog()
}

export const FORGOTPASSWORD = (toast, dispatch, getEmail, setEmailModalValidation, setGetEmail, setEmailModal) => {
  async function forgorpassword() {
    if (getEmail.match(/^(([^<>()[\]\\,;:\s@"]+(\.[^<>()[\]\\,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === null) {
      setEmailModalValidation("Invalid Email Address")
    } else {
      dispatch({
        type: "LOADER__TRIGGER",
        status: true
      });
      try {
        setEmailModalValidation("")
        const data = await axios({
          method: 'POST',
          url: request.forgotPassword,
          data: {
            data: {
              email: getEmail
            }
          }
        })
        if (data?.data[0]?.status) {
          setEmailModal(false)
          setGetEmail("")
          dispatch({
            type: "LOADER__TRIGGER",
            status: false
          });
          return toast.success(`${data?.data[0]?.message}`)
        }
      } catch (e) {
        dispatch({
          type: "LOADER__TRIGGER",
          status: false
        });
        console.log(e)
      }
    }
  }
  forgorpassword()
}

export const LOGINUSERINFO = (dispatch, username, password, toast, checkterms, setcheckterms) => {
  async function loginuserinfo() {
    try {
      const token = await axios({
        method: "post",
        url: request.userLogin,
        data: {
          username: username,
          password: password,
        },
      });
      if (token.data[0].status == false) {
        return toast.error(`${token.data[0].message}`)
      }
      localStorage.setItem("token", token.data);
      localStorage.setItem("user", username);

      if (token.data) {
        try {
          const userData = await axios({
            method: "get",
            url: request.userData,
            headers: {
              Authorization: `Bearer ${token.data}`,
            },
          });
          dispatch({
            type: "SET_USER",
            user: userData.data,
          });
          localStorage.setItem('cookiespop', true)
          localStorage.setItem("userdata", JSON.stringify(userData.data));
          localStorage.setItem(
            "address",
            JSON.stringify(userData.data.addresses)
          );
          localStorage.setItem("userid", userData.data.id);
          localStorage.setItem(
            "username",
            `${userData.data.firstname} ${userData.data.lastname}`
          );
          localStorage.setItem(
            "companyid",
            `${userData.data.extension_attributes.aw_ca_company_user.company_id}`
          );
          localStorage.setItem(
            "company_group_id",
            `${userData.data.extension_attributes.aw_ca_company_user.company_group_id}`
          );
          localStorage.setItem(
            "company_role_id",
            `${userData.data.extension_attributes.aw_ca_company_user.company_role_id}`
          );
          setcheckterms(!checkterms)
        } catch (e) {
          if (
            e.response?.data?.message ===
            "The consumer isn't authorized to access %resources."
          ) {
            localStorage.removeItem("token");
            localStorage.removeItem("userid");
            localStorage.removeItem("userdata");
            localStorage.removeItem("address");
            localStorage.removeItem("cartid");
            localStorage.removeItem("user");
            localStorage.removeItem("companyid");
            localStorage.removeItem("companyrolesdata");
            localStorage.removeItem("company_role_id");
            localStorage.removeItem("company_group_id");
            localStorage.removeItem("timer");
            // window.location.reload();
          }
        } finally {
        }
      }
      getroles();
    } catch (e) {
      console.log(e)
    }
  }
  loginuserinfo()
}

const getroles = async () => {
  if (localStorage.getItem("companyid") === null) return;
  try {
    const rolesdata = await axios({
      method: "get",
      url: `${request.allroles
        }?searchCriteria[filterGroups][0][filters][0][field]=company_id&searchCriteria[filterGroups][0][filters][0][value]=${localStorage.getItem(
          "companyid"
        )}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    });
    localStorage.setItem(
      "companyrolesdata",
      JSON.stringify(rolesdata.data.items)
    );
  } catch (e) {
    if (
      e.response?.data?.message ===
      "The consumer isn't authorized to access %resources."
    ) {
      localStorage.removeItem("token");
      localStorage.removeItem("userid");
      localStorage.removeItem("userdata");
      localStorage.removeItem("address");
      localStorage.removeItem("cartid");
      localStorage.removeItem("user");
      localStorage.removeItem("companyid");
      localStorage.removeItem("companyrolesdata");
      localStorage.removeItem("company_role_id");
      localStorage.removeItem("company_group_id");
      localStorage.removeItem("timer");
      // window.location.reload();
    }
  }
};