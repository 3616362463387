import React, { useState } from 'react'
import './ContentDetails.scss'
import request from '../../../request'
import { useTranslation } from 'react-i18next'
import delivered from '../../../assets/images/MyOrder/Details/delivered.svg'
import deliveryfailed from '../../../assets/images/MyOrder/Details/deliveryfailed.svg'
import orderready from '../../../assets/images/MyOrder/Details/orderready.svg'
import orederrecieved from '../../../assets/images/MyOrder/Details/orederrecieved.svg'
import outfordelivery from '../../../assets/images/MyOrder/Details/outfordelivery.svg'
import DownloadPDF from '../../Finance/DownloadPDF/DownloadPDF'
import { ORDERINVOICEDOWNLOAD } from '../../../store/Finance/Action'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { useStateValue } from '../../../store/state'
import { useLoadScript } from "@react-google-maps/api";
import { Modal } from "react-responsive-modal";
import MapComponent from '../../../pages/OrderModule/GoogleMapShow/MapComponent'
import moment from 'moment'

function ContentDetails(props) {
  const { t } = useTranslation()
  const { branch_name, branch_id, order_summary, invoice_list } = props.data
  const { order_status } = props
  const [{ FINANCE__INVOICE__DATA }, dispatch] = useStateValue()

  const [destination, setdestination] = useState({
    lat: "",
    lng: "",
  });

  const [truckloc, settruckloc] = useState({
    drivermobile: "",
    routecode: "",
  });

  const [libraries] = useState(["places"]);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyAjkVMT-HUgoGIx1XKJezRfNgfIL0vKmpA",
    libraries: libraries,
  });

  const [mapOpen, setMapOpen] = useState(false);
  const onCloseMapModal = () => setMapOpen(false);
  const onOpenMapModal = () => setMapOpen(true);

  const openMap = (lat, lng, mobile, routecode) => {
    settruckloc({
      drivermobile: mobile,
      routecode: routecode,
    });
    setdestination({
      lat: lat,
      lng: lng,
    });
    onOpenMapModal();
  };

  console.log(invoice_list, 'invoice_list')

  return (
    <div className='CONTENTDETAILS'>
      <Modal open={mapOpen} onClose={onCloseMapModal} center>
        {isLoaded ? (
          <MapComponent
            delat={destination?.lat}
            delng={destination?.lng}
            truckloc={truckloc}
          />
        ) : (
          <div>Loading...</div>
        )}
      </Modal>
      <div className='CONTENTDETAILS__INFO'>
        <h5>
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
            <path d="M19.9 5.89L18.85 1.52C18.63 0.62 17.85 0 16.94 0H3.05001C2.15001 0 1.36001 0.63 1.15001 1.52L0.100007 5.89C-0.139993 6.91 0.0800067 7.95 0.720007 8.77C0.800007 8.88 0.910007 8.96 1.00001 9.06V16C1.00001 17.1 1.90001 18 3.00001 18H17C18.1 18 19 17.1 19 16V9.06C19.09 8.97 19.2 8.88 19.28 8.78C19.92 7.96 20.15 6.91 19.9 5.89ZM16.91 1.99L17.96 6.36C18.06 6.78 17.97 7.2 17.71 7.53C17.57 7.71 17.27 8 16.77 8C16.16 8 15.63 7.51 15.56 6.86L14.98 2L16.91 1.99ZM11 2H12.96L13.5 6.52C13.55 6.91 13.43 7.3 13.17 7.59C12.95 7.85 12.63 8 12.22 8C11.55 8 11 7.41 11 6.69V2ZM6.49001 6.52L7.04001 2H9.00001V6.69C9.00001 7.41 8.45001 8 7.71001 8C7.37001 8 7.06001 7.85 6.82001 7.59C6.57001 7.3 6.45001 6.91 6.49001 6.52ZM2.04001 6.36L3.05001 2H5.02001L4.44001 6.86C4.36001 7.51 3.84001 8 3.23001 8C2.74001 8 2.43001 7.71 2.30001 7.53C2.03001 7.21 1.94001 6.78 2.04001 6.36ZM3.00001 16V9.97C3.08001 9.98 3.15001 10 3.23001 10C4.10001 10 4.89001 9.64 5.47001 9.05C6.07001 9.65 6.87001 10 7.78001 10C8.65001 10 9.43001 9.64 10.01 9.07C10.6 9.64 11.4 10 12.3 10C13.14 10 13.94 9.65 14.54 9.05C15.12 9.64 15.91 10 16.78 10C16.86 10 16.93 9.98 17.01 9.97V16H3.00001Z" fill="white" />
          </svg>
          {branch_name} ({order_summary})
        </h5>
      </div>
      {invoice_list?.map((IL, index) => (
        <div className='CONTENTDETAILS__INVOICES' key={index}
          onMouseEnter={() => ORDERINVOICEDOWNLOAD(dispatch, IL?.invoice_book, IL?.invoice_id, "IN")}
        // onMouseEnter={() => ORDERINVOICEDOWNLOAD(dispatch, "IN66", "9016835", "IN")}
        >
          {index !== 0 && <hr />}
          <div className='CONTENTDETAILS__TOP'>
            <p className='CONTENTDETAILS__TOP__INVOICE'>{IL?.invoice_book}{IL?.invoice_id}</p>
            {order_status == "On Delivery" || order_status == "Delivery Completed" ?
              < PDFDownloadLink document={<DownloadPDF data={FINANCE__INVOICE__DATA} />} fileName={`Invoice-${IL?.invoice_book}${IL?.invoice_id}.pdf`}>
                {({ blob, url, loading, error }) =>
                  loading ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      className="CONTENTDETAILS__INVOICES__DOWNLOAD"
                    >
                      <g clip-path="url(#clip0_1_16985)">
                        <path d="M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z" fill="#4396FF" fill-opacity="0.94" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_16985">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      className="CONTENTDETAILS__INVOICES__DOWNLOAD"
                    >
                      <g clip-path="url(#clip0_1_16985)">
                        <path d="M19 9H15V3H9V9H5L12 16L19 9ZM11 11V5H13V11H14.17L12 13.17L9.83 11H11ZM5 18H19V20H5V18Z" fill="#4396FF" fill-opacity="0.94" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1_16985">
                          <rect width="24" height="24" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                }
              </PDFDownloadLink>
              : null}
            <p className='CONTENTDETAILS__TOP__QTY'>{t("Total")} {IL?.product_list?.length}</p>
            <p className='CONTENTDETAILS__TOP__PRICETOTAL'>฿ {IL?.total_price}</p>
            <p className='CONTENTDETAILS__TOP__DELIVERYDATE'>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                <g clip-path="url(#clip0_1_16995)">
                  <path d="M15 6H12.75V3H2.25C1.425 3 0.75 3.675 0.75 4.5V12.75H2.25C2.25 13.995 3.255 15 4.5 15C5.745 15 6.75 13.995 6.75 12.75H11.25C11.25 13.995 12.255 15 13.5 15C14.745 15 15.75 13.995 15.75 12.75H17.25V9L15 6ZM14.625 7.125L16.095 9H12.75V7.125H14.625ZM4.5 13.5C4.0875 13.5 3.75 13.1625 3.75 12.75C3.75 12.3375 4.0875 12 4.5 12C4.9125 12 5.25 12.3375 5.25 12.75C5.25 13.1625 4.9125 13.5 4.5 13.5ZM6.165 11.25C5.7525 10.7925 5.1675 10.5 4.5 10.5C3.8325 10.5 3.2475 10.7925 2.835 11.25H2.25V4.5H11.25V11.25H6.165ZM13.5 13.5C13.0875 13.5 12.75 13.1625 12.75 12.75C12.75 12.3375 13.0875 12 13.5 12C13.9125 12 14.25 12.3375 14.25 12.75C14.25 13.1625 13.9125 13.5 13.5 13.5Z" fill="#323232" />
                </g>
                <defs>
                  <clipPath id="clip0_1_16995">
                    <rect width="18" height="18" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>
                {t("Delivery on")}: {moment(IL?.delivery_date).format("DD MMM, YYYY")}
              </span>
            </p>
            <p className={`CONTENTDETAILS__TOP__STATUS
            ${IL?.delivery_status == "Delivery Completed" && "GREEN"}
            ${IL?.delivery_status == "On Delivery" && "BLUE MAP__CLICK"}
            ${IL?.delivery_status == "Order Received" && "YELLOW"}
            ${IL?.delivery_status == "Order Failed" && "RED"}
            ${IL?.delivery_status == "Order Confirmed" && "YELLOW"}
            ${IL?.delivery_status == "Order Cancelled" && "RED"}
            ${IL?.delivery_status == "Invoice Cancelled" && "RED"}
            ${IL?.delivery_status == "Invoice Completed" && "YELLOW"}
            ${IL?.delivery_status == "Delivery Failed" && "RED"}
            ${IL?.delivery_status == "Closed for Vat" && "GREEN"}
            `}>
              {IL?.delivery_status == "On Delivery" ?
                <span
                // onClick={() => openMap(IL?.latitude, IL?.longitude, IL?.driver_mobile, IL.route_code)}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <g clip-path="url(#clip0_789_34617)">
                      <path d="M8.00016 1.33398C5.42016 1.33398 3.3335 3.42065 3.3335 6.00065C3.3335 9.50065 8.00016 14.6673 8.00016 14.6673C8.00016 14.6673 12.6668 9.50065 12.6668 6.00065C12.6668 3.42065 10.5802 1.33398 8.00016 1.33398ZM4.66683 6.00065C4.66683 4.16065 6.16016 2.66732 8.00016 2.66732C9.84016 2.66732 11.3335 4.16065 11.3335 6.00065C11.3335 7.92065 9.4135 10.794 8.00016 12.5873C6.6135 10.8073 4.66683 7.90065 4.66683 6.00065Z" fill="#1F80FA" />
                      <path d="M8.00016 7.66732C8.92064 7.66732 9.66683 6.92113 9.66683 6.00065C9.66683 5.08018 8.92064 4.33398 8.00016 4.33398C7.07969 4.33398 6.3335 5.08018 6.3335 6.00065C6.3335 6.92113 7.07969 7.66732 8.00016 7.66732Z" fill="#1F80FA" />
                    </g>
                    <defs>
                      <clipPath id="clip0_789_34617">
                        <rect width="16" height="16" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                  {t(IL?.delivery_status)}
                </span>
                :
                <span>{t(IL?.delivery_status)}</span>
              }
            </p>
          </div>
          {IL?.product_list?.map((el, i) => (
            <>
              <div className='CONTENTDETAILS__BOTTOM' key={i}>
                <img src={`${request.image}media/catalog/product${el?.product_image}`} alt="" />
                <p className='CONTENTDETAILS__INVOICES__NAMESKU'>
                  <span className='CONTENTDETAILS__BOTTOM__NAME' title={el?.product_name}>{el?.product_name}</span>
                  <span className='CONTENTDETAILS__BOTTOM__SKU'>SKU : {el?.sku}</span>
                </p>
                <p className='CONTENTDETAILS__BOTTOM__PRICE'>฿ {(el?.product_price)}</p>
                <p className='CONTENTDETAILS__BOTTOM__QTY'>x{parseInt(el?.product_qty)} {el?.unit}</p>
                <p className='CONTENTDETAILS__BOTTOM__TOTAL'>฿ {parseFloat(el?.base_row_total).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
              </div>
              {/* {i !== IL.product_list.length - 1 &&  window.innerWidth > 912 && <hr />} */}
            </>
          ))}
        </div>
      ))}
    </div >
  )
}

export default ContentDetails