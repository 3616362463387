import React from 'react'
import './OrderingCards.scss'
import { useTranslation } from 'react-i18next'
import moment from 'moment'

function OrderingCards(props) {
  const { DELIVER_DATE, SELLING_PRICE, SELLING_UNIT, orderPrice, orderUnit, STATUS } = props.data
  const { OrderTakingSelection, index, OTSelection } = props
  const { t } = useTranslation()

  // CURRENCT FORMAT FUNCTION
  function formatToCurrency(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <div className={`ORDERINGCARDS ${STATUS === "N" && "UPCOMMING__ORDER"} ${STATUS === "O" && OTSelection === index && "SELECTEDORDER"}`} onClick={() => OrderTakingSelection(index)}>
     <p className='ORDERINGCARDS__DATE'>{moment(DELIVER_DATE).format("D MMM YYYY, ddd")}</p>
      {STATUS !== "N"
        ? <>
          <span>{t("Unit Price")}</span>
          <p className='ORDERINGCARDS__SELLING__PRICE'>
            <span className="ORDERINGCARDS__CURRENCY">฿</span>
            <span className="ORDERINGCARDS__PRICE">{formatToCurrency(parseFloat(SELLING_PRICE).toFixed(2))}</span>
            <span className="ORDERINGCARDS__UNIT">/{SELLING_UNIT}</span>
          </p>
        </>
        : <p className='UPCOMMING__ORDER__CONTENT'
        // onClick={() => OrderTakingSelection('upcomming')}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g clip-path="url(#clip0_145_34104)">
              <path d="M12.8235 2C7.3035 2 2.8335 6.48 2.8335 12C2.8335 17.52 7.3035 22 12.8235 22C18.3535 22 22.8335 17.52 22.8335 12C22.8335 6.48 18.3535 2 12.8235 2ZM12.8335 20C8.4135 20 4.8335 16.42 4.8335 12C4.8335 7.58 8.4135 4 12.8335 4C17.2535 4 20.8335 7.58 20.8335 12C20.8335 16.42 17.2535 20 12.8335 20ZM13.3335 7H11.8335V13L17.0835 16.15L17.8335 14.92L13.3335 12.25V7Z" fill="#1C3454" fill-opacity="0.26" />
            </g>
            <defs>
              <clipPath id="clip0_145_34104">
                <rect width="24" height="24" fill="white" transform="translate(0.833496)" />
              </clipPath>
            </defs>
          </svg>
          <span>Upcoming Order</span>
        </p>
      }
    </div>
  )
}

export default OrderingCards