import React from 'react'
import './MainBlock.scss'
import moment from 'moment';
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useTranslation } from 'react-i18next';

function MainBlock(props) {
  const { delivery_date, order_id, order_date, order_total, ship_to, status } = props
  const { t } = useTranslation()
  const history = useHistory()

  function formatToCurrency(price) {
    var outstanding = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return outstanding?.replace(/\.(\d{1,2}).*$/, '.$1')
  }

  const selectOrder = () => {
    if (window.innerWidth > 912) return
    history.push(`/myorder/${order_id}`)
  }

  return (
    <div className='MAINBLOCK'>
      <div className='MAINBLOCK__HEAD' onClick={selectOrder}>
        <div className='MAINBLOCK__HEAD__LEFT'>
          <p className='MAINBLOCK__ORDERNO'>{t("Order ID")}: #{order_id}</p>
          <p className='MAINBLOCK__DATE'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clip-path="url(#clip0_1_16697)">
                <path d="M19 3H18V1H16V3H8V1H6V3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM19 19H5V9H19V19ZM5 7V5H19V7H5ZM7 11H17V13H7V11ZM7 15H14V17H7V15Z" fill="#1C304A" fill-opacity="0.52" />
              </g>
              <defs>
                <clipPath id="clip0_1_16697">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <span>{moment(order_date).format("DD MMM, YYYY")}</span>
          </p>
        </div>
        <div className='MAINBLOCK__HEAD__RIGHT'>
          <p className='MAINBLOCK__TOTAL'>฿ {formatToCurrency(parseFloat(order_total).toFixed(2))} {window.innerWidth <= 912 && <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
            <g clip-path="url(#clip0_1_14978)">
              <path d="M9.99984 6.5L8.58984 7.91L13.1698 12.5L8.58984 17.09L9.99984 18.5L15.9998 12.5L9.99984 6.5Z" fill="#22A38C" />
            </g>
            <defs>
              <clipPath id="clip0_1_14978">
                <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>}</p>
          <Link to={`/myorder/${order_id}`} className='MAINBLOCK__DETAILS'>{t("View Details")}</Link>
        </div>
      </div>
      <div className='MAINBLOCK__TABLE'>
        <div className='MAINBLOCK__TABLE__HEAD'>
          <span className='FIRST'>{t("Ship to")}</span>
          <span>{t("Delivery Date")}</span>
          <span>{t("Total Price")}</span>
          <span>{t("Invoice No.")}</span>
          <span>{t("Status")}</span>
        </div>
        {/* {window.innerWidth > 920 && <hr />} */}
        {ship_to?.map((el, i) => (
          <>
            {window.innerWidth > 920 && <hr key={`${i}HR`} />}
            <div className='MAINBLOCK__TABLE__BODY' key={i}>
              <p className='FIRST'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                  <path d="M19.9 5.89L18.85 1.52C18.63 0.62 17.85 0 16.94 0H3.05001C2.15001 0 1.36001 0.63 1.15001 1.52L0.100007 5.89C-0.139993 6.91 0.0800067 7.95 0.720007 8.77C0.800007 8.88 0.910007 8.96 1.00001 9.06V16C1.00001 17.1 1.90001 18 3.00001 18H17C18.1 18 19 17.1 19 16V9.06C19.09 8.97 19.2 8.88 19.28 8.78C19.92 7.96 20.15 6.91 19.9 5.89ZM16.91 1.99L17.96 6.36C18.06 6.78 17.97 7.2 17.71 7.53C17.57 7.71 17.27 8 16.77 8C16.16 8 15.63 7.51 15.56 6.86L14.98 2L16.91 1.99ZM11 2H12.96L13.5 6.52C13.55 6.91 13.43 7.3 13.17 7.59C12.95 7.85 12.63 8 12.22 8C11.55 8 11 7.41 11 6.69V2ZM6.49001 6.52L7.04001 2H9.00001V6.69C9.00001 7.41 8.45001 8 7.71001 8C7.37001 8 7.06001 7.85 6.82001 7.59C6.57001 7.3 6.45001 6.91 6.49001 6.52ZM2.04001 6.36L3.05001 2H5.02001L4.44001 6.86C4.36001 7.51 3.84001 8 3.23001 8C2.74001 8 2.43001 7.71 2.30001 7.53C2.03001 7.21 1.94001 6.78 2.04001 6.36ZM3.00001 16V9.97C3.08001 9.98 3.15001 10 3.23001 10C4.10001 10 4.89001 9.64 5.47001 9.05C6.07001 9.65 6.87001 10 7.78001 10C8.65001 10 9.43001 9.64 10.01 9.07C10.6 9.64 11.4 10 12.3 10C13.14 10 13.94 9.65 14.54 9.05C15.12 9.64 15.91 10 16.78 10C16.86 10 16.93 9.98 17.01 9.97V16H3.00001Z" fill="#37BFA7" />
                </svg>
                <span>{el?.branch_name}</span>
              </p>
              <div className='MAINBLOCK__TABLE__LIST'>
                {el?.invoice?.map((d, index) => (
                  <p key={index}>
                    <span >{d?.delivery_date ? moment(d?.delivery_date).format("DD MMM, YYYY") : null}</span>
                    <span >฿ {formatToCurrency(parseFloat(d?.price).toFixed(2))}</span>
                    <span >{d?.invoice_book}{d?.invoice_no}</span>
                    <span
                      className={`
                    ${d?.status == "Delivery Completed" && "GREEN"}
                    ${d?.status == "On Delivery" && "BLUE"}
                    ${d?.status == "Order Received" && "YELLOW"}
                    ${d?.status == "Order Failed" && "RED"}
                    ${d?.status == "Order Confirmed" && "YELLOW"}
                    ${d?.status == "Order Cancelled" && "RED"}
                    ${d?.status == "Invoice Cancelled" && "RED"}
                    ${d?.status == "Invoice Completed" && "YELLOW"}
                    ${d?.status == "Delivery Failed" && "RED"}
                    ${d?.status == "Closed for Vat" && "GREEN"}
                `}>
                      {d?.status == "On Delivery" ?
                        <>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g clip-path="url(#clip0_789_34617)">
                              <path d="M8.00016 1.33398C5.42016 1.33398 3.3335 3.42065 3.3335 6.00065C3.3335 9.50065 8.00016 14.6673 8.00016 14.6673C8.00016 14.6673 12.6668 9.50065 12.6668 6.00065C12.6668 3.42065 10.5802 1.33398 8.00016 1.33398ZM4.66683 6.00065C4.66683 4.16065 6.16016 2.66732 8.00016 2.66732C9.84016 2.66732 11.3335 4.16065 11.3335 6.00065C11.3335 7.92065 9.4135 10.794 8.00016 12.5873C6.6135 10.8073 4.66683 7.90065 4.66683 6.00065Z" fill="#1F80FA" />
                              <path d="M8.00016 7.66732C8.92064 7.66732 9.66683 6.92113 9.66683 6.00065C9.66683 5.08018 8.92064 4.33398 8.00016 4.33398C7.07969 4.33398 6.3335 5.08018 6.3335 6.00065C6.3335 6.92113 7.07969 7.66732 8.00016 7.66732Z" fill="#1F80FA" />
                            </g>
                            <defs>
                              <clipPath id="clip0_789_34617">
                                <rect width="16" height="16" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          {t(d?.status)}</>
                        :
                        t(d?.status)
                      }
                    </span>
                  </p>
                ))}
              </div>
            </div>
          </>
        ))}
      </div>
    </div>
  )
}

export default MainBlock