import axios from "axios";
import request from "../../request";

export const CAMPAIGNPRODUCTS = (dispatch, TOKEN, COMPANYID, STOREID, USERID, Id,storeid, categoryId,type_id,currentpage) => {
    async function fetchCampaignProducts() {
        const url = `${request.CAMPAIGNPRODUCTS}/${Id}/${STOREID}/${categoryId}?&type_id=${type_id}&current_page=${currentpage}`
    
        dispatch({ type: 'CAMPAIGN_PRODUCTS_LOADING' });
    
        try {
            const response = await axios({
                method: "post",
                url: url,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                }
            });
        
            if (response.data) {
                const responseData = response.data[0].data;
                const products = responseData.products;
                const bannerImage = responseData?.details?.banner_image;
                const responsiveImage = responseData?.details?.banner_image_mobile;
                const filtertitle = responseData?.details?.title;
                const thaititle = responseData?.details?.titlethai;
                const filterdetails = responseData?.details?.description;
                const filterdetailsthai = responseData?.details?.descriptionthai;
              
                dispatch({
                    type: "CAMPAIGN_PRODUCTS_SUCCESS",
                    payload: {
                        products: products,
                        bannerImage: bannerImage,
                        responsiveImage: responsiveImage, 
                        filtertitle: filtertitle,
                        filterdetails:filterdetails,
                        thaititle:thaititle,
                        filterdetailsthai:filterdetailsthai
                    }
                });
            } else {
                dispatch({
                    type: 'CAMPAIGN_PRODUCTS_FAILURE',
                    payload: 'No products data found'
                });
            }
        } catch (error) {
            dispatch({
                type: 'CAMPAIGN_PRODUCTS_FAILURE',
                payload: error.message
            });
        }
    }

    fetchCampaignProducts();
}

export const CAMPAIGNPRODUCTSFilterproduct = (dispatch, TOKEN, Id, STOREID, categoryId) => {
    async function fetchCampaignProductsFilter() {
        const url = `${request.CAMPAIGNPRODUCTSFilter}/${Id}/${STOREID}`;
        
        dispatch({ type: 'CAMPAIGN_PRODUCTS_FILTER_LOADING' });

        try {
            const response = await axios({
                method: "post",
                url: url,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                }
            });
            if (response.data && Array.isArray(response.data)) {
                const responseData = response.data; 
                dispatch({
                    type: "CAMPAIGN_PRODUCTS_FILTER_SUCCESS",
                    payload: responseData 
                });
            } else {
                dispatch({
                    type: 'CAMPAIGN_PRODUCTS_FILTER_FAILURE',
                    payload: 'No filter data found'
                });
            }
        } catch (error) {
            dispatch({
                type: 'CAMPAIGN_PRODUCTS_FILTER_FAILURE',
                payload: error.message
            });
        }
    }

    fetchCampaignProductsFilter();
}
