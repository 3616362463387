import axios from "axios";
import request from "../../request";
import moment from "moment";

export const ORDERMAIN = async (dispatch, COMPANYID, TOKEN, startDate, endDate) => {
    try {
        const response = await axios({
            method: "post",
            url: request.ORDERMAIN,
            data: {
                company_id: COMPANYID,
                startDate: moment(startDate).format("YYYY-MM-DD"),
                endDate: moment(endDate).format("YYYY-MM-DD")
            },
            headers: {
                "Authorization": `Bearer ${TOKEN}`,
            },
        });

        const data = response.data;
        dispatch({
            type: "ORDER__MAIN",
            payload: data
        });
    } catch (e) {
        console.log(e);
    }
};

export const ORDERDDETAILS = (dispatch, orderid, USERID, TOKEN) => {
    async function ordedetails() {
        try {
            const data = await axios({
                method: "post",
                url: request.orderdetails,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
                data: {
                    order_id: orderid,
                    customer_id: USERID,
                },
            })
            dispatch({
                type: "ORDER__DETAILS",
                payload: data?.data
            })
        } catch (e) {
            console.log(e)
        }
    };
    ordedetails();
}

export const REORDER = (dispatch, history, orderid, toast) => {
    async function reorder() {
        try {
            const data = await axios({
                method: "post",
                url: request.reorder,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
                data: {
                    order_id: orderid,
                    quote_id: localStorage.getItem("cartid"),
                },
            })
            toast.info(data?.data[0]?.message);
            dispatch({
                type: "CART_STATUS",
            });
            history.push("/checkout");
        } catch (e) {
            console.log(e)
        }
    };
    reorder();
}