import React, { useEffect, useMemo, useState } from 'react'
import './Cart.scss'
import { useStateValue } from '../../store/state';
import { useTranslation } from "react-i18next";
import MUIDataTable from 'mui-datatables';
import ClearAll from '../../assets/images/cart/delete.svg'
import request from '../../request';
import Summary from '../../components/Summary/Summary';
import axios from 'axios';
import { browserName, browserVersion } from "react-device-detect";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function Cart() {
  const { t } = useTranslation();
  const [columns, setColumns] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [{ cart, summary }, dispatch] = useStateValue();
  const [loader, setLoader] = useState(false)
  const [subTotal, setSubTotal] = useState([])

  function formatToCurrency(price) {
    var outstanding = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return outstanding?.replace(/\.(\d{1,2}).*$/, '.$1')
  }

  useEffect(() => {
    const initialRows = cart?.map((el, i) => {
      if (el?.item_selected == "1") {
        return ({ index: i, dataIndex: i })
      }
    })
    setSelectedRows({ rowsSelected: [...initialRows] });
  }, [cart]);

  // OPTIONS FOR DATA TABLE
  const options = {
    filter: false,
    filterType: "dropdown",
    responsive: "standard",
    download: false,
    print: false,
    sort: false,
    pagination: false,
    viewColumns: false,
    search: false,
    selectableRows: "multiple",
    onRowSelectionChange: (rowsSelected, allRows) => {
      console.log(allRows, 'allRows')
      if (!allRows?.length) {
        setSelectedRows({ rowsSelected: [] })
        UpdateRow({ rowsSelected: [] })
      } else {
        setSelectedRows({
          rowsSelected: allRows.map(row => {
            return {
              index: row.index, dataIndex: row.dataIndex
            }
          })
        });
        UpdateRow({
          rowsSelected: allRows.map(row => {
            return {
              index: row.index, dataIndex: row.dataIndex
            }
          })
        });
      }
    },
    textLabels: {
      body: {
        noMatch: (
          <div className="no_data_found">
            <p>{t("No data Found...")}</p>
          </div>
        ),
      },
      pagination: {
        rowsPerPage: t("Rows per page:"),
        displayRows: t("of"),
      },
    },
  }

  const UpdateRow = async (value) => {
    let addid = [];
    let removeid = [];
    value?.rowsSelected?.filter(SR => {
      addid.push(cart[SR?.index]?.item_id)
    })

    cart?.filter(el => {

      if (addid.some(item => el?.item_id?.includes(!item))) {
        removeid.push(el?.item_id)
      }

      if (!value?.rowsSelected?.length) {
        removeid.push(el?.item_id)
      }
    })


    if (addid.length > 0) {
      try {
        await axios({
          method: "post",
          url: request.quoteitemupdate,
          data: {
            cartId: localStorage.getItem("cartid"),
            value: 1,
            itemId: addid,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }
    if (removeid.length > 0) {
      try {
        await axios({
          method: "post",
          url: request.quoteitemupdate,
          data: {
            cartId: localStorage.getItem("cartid"),
            value: 0,
            itemId: removeid,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
      } catch (e) {
        console.log(e);
      }
    }

    dispatch({
      type: "SET_MEGAMENU_HIDE",
      value: false,
    });
    dispatch({
      type: "CART_STATUS",
    });
  }

  const deletecartitem = async (item_id, sku, name) => {
    try {
      await axios({
        method: "post",
        url: `${request.deletecartitem}${localStorage.getItem("userid")}/${item_id
          }/${sku}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      createlog(name);
      dispatch({
        type: "CART_STATUS",
      });
      await axios({
        method: "post",
        url: request.splitorderdelete,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          data: {
            id: 1,
          },
        },
      });
    } catch (e) {
      console.log(e)
      // toast.error(e.response?.data?.message);
    }
  };
  // LOGS CREATION - API
  const createlog = async (name) => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      await axios({
        method: "post",
        url: request.newlog,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          data: {
            customer_id: localStorage.getItem("userid"),
            company_id: localStorage.getItem("companyid"),
            remote_ip: res.data.IPv4,
            user_agent: `${browserName} Version:${browserVersion}`,
            action: `${JSON.parse(localStorage.getItem("userdata")).firstname
              } deleted ${name} from the cart`,
            action_type: "save",
            functioncall_url: "user/save/",
          },
        },
      });
      await axios({
        method: "post",
        url: request.createnotification,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          data: {
            customer_id: localStorage.getItem("userid"),
            company_id: localStorage.getItem("companyid"),
            remote_ip: res.data.IPv4,
            user_agent: `${browserName} Version:${browserVersion}`,
            action_data: `${JSON.parse(localStorage.getItem("userdata")).firstname
              } deleted ${name} from the cart`,
            action_type: "save",
            functioncall_url: "user/save/",
            status: 0,
            category: "notification"
          },
        },
      });
    } catch (e) {
      console.log(e)
      // toast.error(e.response?.data?.message);
    }
  };


  useEffect(() => {
    setColumns([
      {
        name: 'name', label: `${t("PRODUCT")}`, options: {
          customBodyRender: (value, tableMeta) => {
            let SKU = tableMeta.rowData?.[6]?.[0]?.sku
            let image = tableMeta.rowData?.[7]
            let product_id = tableMeta?.rowData?.[12]
            let quote = tableMeta?.rowData?.[13]
            let url_key = tableMeta?.rowData?.[14]
            let category_id = tableMeta?.rowData?.[15]
            let order_taking = tableMeta?.rowData?.[16]
            return (
              <div className={`cart__product ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <p>
                  <img alt="" src={`${request.image}media/catalog/product${image}`}></img>
                  <a href={`/pdp/${!quote ? "catalog" : "product"}/${order_taking == "Y" ? "ordertaking" : "stock"}/${category_id}/${product_id}/${url_key}`}
                    target='_blank'
                  >
                    <span className='cart__product__name'>{value}</span>
                    <span className='cart__product__sku'>{SKU}</span>
                  </a>
                </p>
              </div>
            );
          },
        }
      },
      {
        name: 'price', label: `${t("UNITPRICE")}`, options: {
          customBodyRender: (value) => {
            return (
              <div className={`cart__unitprice ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <span>฿ {formatToCurrency(parseFloat(value).toFixed(2))}</span>
              </div>
            );
          },
        }
      },
      {
        name: 'qty', label: `${t("QUANTITY")}`, options: {
          customBodyRender: (value, tableMeta) => {
            let name = tableMeta?.rowData?.[0]
            let unit = tableMeta?.rowData?.[5]
            let item_id = tableMeta?.rowData?.[8]
            let qty = tableMeta?.rowData?.[9]
            let sku = tableMeta?.rowData?.[10]
            let quote_id = tableMeta?.rowData?.[11]
            let product_id = tableMeta?.rowData?.[12]
            let delivery_date = tableMeta?.rowData?.[6]?.[0]?.delivery_date
            // return (<div className="loader"></div>);
            return (
              loader ? <div className="loader"></div> :
                <div className={`cart__qty ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                  <span>
                    {/* <img src={Subtract} alt="" onClick={() => minusqty(item_id, qty, sku, quote_id, product_id, name, delivery_date)} /> */}
                    <span className='qty__qty'>{parseInt(value)}</span>
                    {/* <img src={Add} alt="" onClick={() => addqty(item_id, qty, sku, quote_id, product_id, name, delivery_date)} /> */}
                    <span className='qty__unit'>{unit}</span>
                  </span>
                </div>
            );
          },
        }
      },
      {
        name: '', label: `${t("TOTALPRICE")}`, options: {
          customBodyRender: (value, tableMeta) => {
            let price = tableMeta?.rowData?.[1]
            let unit = tableMeta?.rowData?.[2]
            return (
              <div className={`cart__totalprice ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <span>฿ {formatToCurrency(parseFloat(price * unit).toFixed(2))}</span>
              </div>
            );
          },
        }
      },
      {
        name: '', label: '', options: {
          customBodyRender: (value, tableMeta) => {
            let item_id = tableMeta?.rowData?.[8]
            let sku = tableMeta?.rowData?.[10]
            let name = tableMeta?.rowData?.[0]
            return (
              <div className='cart__delete'>
                <img src={ClearAll} alt="" onClick={() => deletecartitem(item_id, sku, name)} />
              </div>
            )
          }
        }
      },
      {
        name: 'unit', label: '', options: {
          display: false
        }
      },
      {
        name: 'splitorder', label: '', options: {
          display: false
        }
      },
      {
        name: 'image', label: '', options: {
          display: false
        }
      },
      {
        name: 'item_id', label: '', options: {
          display: false
        }
      },
      {
        name: 'qty', label: '', options: {
          display: false
        }
      },
      {
        name: 'sku', label: '', options: {
          display: false
        }
      },
      {
        name: 'quote_id', label: '', options: {
          display: false
        }
      },
      {
        name: 'product_id', label: '', options: {
          display: false
        }
      },
      {
        name: 'quote', label: '', options: {
          display: false
        }
      },
      {
        name: 'url_key', label: '', options: {
          display: false
        }
      },
      {
        name: 'category_id', label: '', options: {
          display: false
        }
      },
      {
        name: 'order_taking', label: '', options: {
          display: false
        }
      },

    ])
    setSubTotal([])
    cart.filter(C => setSubTotal(prevState => [...prevState, C?.price * C?.qty]))
  }, [cart])


  const clearCart = async () => {
    try {
      await axios({
        method: "delete",
        url: `${request.clearcart}/${localStorage.getItem("cartid")}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch({
        type: "CART_STATUS",
      });
    } catch (e) {
      console.log(e)
      // toast.error(e.response?.data?.message);
    }
  };

  const MUITABLEMEMO = useMemo(() => {
    return <MUIDataTable
      data={cart?.length ? cart : []}
      columns={columns}
      options={{
        ...options,
        count: cart?.length,
        rowsSelected: selectedRows?.rowsSelected?.map((row) => row?.index),
      }}
    />
  }, [cart, selectedRows])


  return (
    <div className='cart'>
      {window.innerWidth > 912 ? <div className='cart__breadcrumbs'>
        <Link to="/">{t("Home")}</Link>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <g clip-path="url(#clip0_1_18599)">
            <path d="M10.0199 6L8.60986 7.41L13.1899 12L8.60986 16.59L10.0199 18L16.0199 12L10.0199 6Z" fill="#323232" />
          </g>
          <defs>
            <clipPath id="clip0_1_18599">
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <span>{t("Cart")}</span>
      </div> : null}
      <h4 className='cart__title'>{t("CARTSUMMARY")}</h4>
      <div className='cart__content'>
        {window.innerWidth <= 912 && cart?.length > 0 &&
          <p className='cart__clearall'>
            <span onClick={clearCart}>{t("CLEARALL")} ({cart.length})</span>
          </p>
        }
        <div className='cart__left'>
          {MUITABLEMEMO}

          {window.innerWidth > 912 &&
            cart?.length > 0 &&
            <button className='cart__clearall' onClick={clearCart}>
              {t("CLEARALL")}
              <img src={ClearAll} alt="" />
            </button>
          }
        </div>
        <div className='cart__right'>
          <Summary
            label1="Merchandise Subtotal"
            value1={parseFloat(subTotal?.reduce((a, b) => a + b, 0).toFixed(2))}
            label2={`${t("VAT")} (${summary?.tax_amount > 0 ? "7" : "0"}%)`}
            value2={summary?.tax_amount}
            label3="Total Payment"
            value3={subTotal?.reduce((a, b) => a + b, 0)}
            button={"Checkout"}
            cartlength={cart?.length}
          />
        </div>
      </div>
    </div>
  )
}

export default Cart