import React, { useEffect, useMemo, useRef, useState } from 'react'
import './PDPDetails.scss'
import request from '../../../../request'
import axios from 'axios'
import OrderingCards from './OrderingCards/OrderingCards'
import { useTranslation } from "react-i18next";
import carticon from "../../../../assets/images/catalog/shopping_cart.svg";
import { RequestQatation } from '../../../CatalogComponents/CatalogPopOver/RequestQatation'
import { toast } from 'react-toastify'
import moment from 'moment'
import { useStateValue } from '../../../../store/state'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import MoreInfo from '../../MoreInfo/MoreInfo'
import Timer from './Timer/Timer'
import MobieFav from '../../../../assets/images/PDP/Mobile/MobieFav.svg'
import MobileFavRem from '../../../../assets/images/PDP/Mobile/MobileFavRem.svg'
import { ADDFAV, DELETEFAV, DateOrderDeliveryForStockProduct, SEASONS } from '../../../../store/PDP/Action'
import { DateOrderDelivery } from '../../../../store/PDP/Action'
import timer from '../../../../assets/images/PDP/Mobile/timer.svg'
import StockProductDateCards from './StockProductDateCards/StockProductDateCards'

function PDPDetails({ pid, currentpdpdata, setrefreshpdp, setCurrentSku }) {
  const [mouseEnter, setMouseEnter] = useState(false)
  const { t } = useTranslation();
  const [qty, setQty] = useState(1);
  const [{ cart, SEASONS__DATA__LIST, ERP__HOLIDAYS__LIST }, dispatch] = useStateValue();
  const [currentunits, setcurrentunits] = useState();
  const { page } = useParams();
  const qtyRef = useRef(1);
  const [OTSelection, setOTSELECTION] = useState(0)
  const [currentOrder, setCurrentOrder] = useState()
  const specialText = useRef()
  const [ORDERTAKING__DATES__DATA, setOrderingDates] = useState([]);
  const [addressdata, setaddressdata] = useState([]);
  const [defaultBranchId, setDefaultBranchId] = useState('');
  const addlist = useRef();
  const [branchAddresses, setBranchAddresses] = useState([]);
  const [dateavail, setdateavail] = useState([]);

  const getDayName = (day) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    return days[day];
  }
  const isSunday = (date) => {
    const day = date.getDay();
    return day !== 0;
  };


  const getNexAvaliableDate = (preferredDay, deliveryDates) => {
    function isDaballed(date) {
      if (!preferredDay) {
        return false;
      }

      const day = date.getDay();
      const dayName = getDayName(day);

      // Check if the day should be disabled based on the provided availability
      const isDayDisabled = preferredDay[dayName] === "Y";

      // Get the min and max dates within the selectable range
      const minSelectableDate = new Date(dateavail[0]?.DD);
      const maxSelectableDate = new Date(dateavail[dateavail?.length - 1]?.DD);

      // Check if the date is a Sunday and within the selectable range
      const isSundayAndSelectable =
        isSunday(date) &&
        date >= minSelectableDate &&
        date <= maxSelectableDate;

      // Check if the date is a holiday
      const isHoliday = ERP__HOLIDAYS__LIST.some((holiday) => {
        const holidayDate = new Date(holiday.HOLIDAY_DATE);
        return holidayDate.toDateString() === date.toDateString();
      });

      // Disable the date if it meets any of the specified conditions
      if ((!isDayDisabled && !isHoliday) || (isDayDisabled && isHoliday)) {
        return false;
      }

      // Disable the date if it's not within the selectable range, the day is marked as disabled, or it's a holiday
      // if (isSundayAndSelectable) {
      //   return isSundayAndSelectable
      // }
      if (isHoliday) {
        return isHoliday;
      }
      if (isDayDisabled) {
        return isDayDisabled;
      }
    }

    let availableDate = null;

    // Loop through each delivery date
    for (let i = 0; i < deliveryDates?.length; i++) {
      const currentDate = new Date(deliveryDates[i].DD);
      if (isDaballed(currentDate)) {
        availableDate = deliveryDates[i].DD;
        break;
      }
    }

    return availableDate;

  };

  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("userid") === null) return;
      try {
        const address = await axios({
          method: "get",
          url: `${request.getbranch}/${localStorage.getItem("userid")}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setaddressdata(address.data);
        const objWithChkDefaultY = address?.data.find(obj => obj.chk_default === "Y");
        setDefaultBranchId(objWithChkDefaultY?.address_id);
        addlist.current = address.data;

        const addresses = address.data.map(data => ({
          city: data.city,
          country_name: data.country_name,
          customer_id: data.customer_id,
          firstname: data.firstname,
          id: data.address_id,
          branch_id: data.address_id,
          lastname: data.lastname,
          postalcode: data.post_code,
          region: data.state_name,
          region_code: data.state,
          region_id: data.state_id,
          street: data.street,
          telephone: data.telephone,
          branch: data.branch_name,
          items: [],
          Friday: data.d_fri,
          Monday: data.d_mon,
          Saturday: data.d_sat,
          Sunday: data.d_sun,
          Thursday: data.d_thu,
          Tuesday: data.d_tue,
          Wednesday: data.d_wed,
        }));
        setBranchAddresses(addresses);


        const preferredDay = addresses?.find((el) => el?.branch_id == objWithChkDefaultY?.address_id);

        const availableDate = getNexAvaliableDate(preferredDay, ORDERTAKING__DATES__DATA)
        // here need to find index 
        const availableDateIndex = ORDERTAKING__DATES__DATA.findIndex(obj => obj.DD === availableDate);

        setOTSELECTION(availableDateIndex || 0)
       
        if(ORDERTAKING__DATES__DATA[availableDateIndex]){
          setCurrentOrder(ORDERTAKING__DATES__DATA[availableDateIndex])
        }
        
      } catch (e) {
        console.log(e)
      }
    };

    fetchData();
  }, [localStorage.getItem("userid"), ORDERTAKING__DATES__DATA]);




  const AddtoFav = () => {
    if (view === true) {
      let message = `${currentpdpdata?.name} ${t("Removed from the Favourite Successfully")}`
      // deletefromFav();
      DELETEFAV(dispatch, pid, view, setView, toast, message, setrefreshpdp);
    } else {
      addToFavHandler();
      setrefreshpdp(true)
    }
  }

  const OrderTakingSelection = (data) => {
    if (ORDERTAKING__DATES__DATA[data]?.STATUS == "N") return
    // let selectableData = ORDERTAKING__DATES__DATA?.filter(el => el?.STATUS === "O")
    setOTSELECTION(data)
    setCurrentOrder(ORDERTAKING__DATES__DATA[data])
  }

  useEffect(() => {
    let selectableData = ORDERTAKING__DATES__DATA?.filter(el => el?.STATUS === "O")
    if (!selectableData?.length) {
      let upcomming = ORDERTAKING__DATES__DATA?.filter(el => el?.STATUS === "N")
      if (upcomming?.length) {
        setCurrentOrder(upcomming[0])
      }
    } else {
      setCurrentOrder(selectableData[0])
    }
  }, [ORDERTAKING__DATES__DATA])

  useEffect(() => {
    SEASONS(dispatch, currentpdpdata?.sku);
    // If stock products Need to get delivery dates from API-s
    if (currentpdpdata?.sku && currentpdpdata?.order_taking === 'N') {
      DateOrderDeliveryForStockProduct(currentpdpdata.sku)
        .then(res => {
          setOrderingDates(res);
        })
        .catch(error => {
          console.error('Error fetching order delivery dates:', error);
        });
    }
    // If order taking products Need to get delivery dates from API
    if (currentpdpdata?.sku && currentpdpdata?.order_taking != 'N') {
      DateOrderDelivery(currentpdpdata.sku)
        .then(res => {
          setOrderingDates(res);
        })
        .catch(error => {
          console.error('Error fetching order delivery dates:', error);
        });
    } else {
      // If SKU is not available, reset state
      setOrderingDates(null);
      // SEASONS(currentpdpdata?.sku)
    }
  }, [currentpdpdata?.sku])

  useEffect(() => {
    if (currentpdpdata?.options === undefined) {
      return;
    } else {
      setcurrentunits(currentpdpdata?.options[0]);
    }
    setCurrentSku(currentpdpdata?.sku)
  }, [currentpdpdata]);

  // useEffect(() => {
  //   //setcunit({ title: currentunits?.order_type, price: currentunits?.price });
  //  }, [currentunits]);

  // UPDATE QTY BY INPUT
  const updateqty = (value) => {
    if (value.toString()?.length > 4) return;
    setQty(value);
    qtyRef.current = value
  };

  // DECREMENT QTY
  const subtractQuantity = () => {
    if (qty === 1) {
      return;
    } else {
      setQty(qty - 1);
      qtyRef.current = qty - 1
    }
  };

  // INCREMENT QTY
  const addQuantity = () => {
    if (qty === 9999) return
    setQty(qty + 1);
    qtyRef.current = qty + 1
  };

  // API TO CHECK DATE WHEN ADDING TO THE CART
  const checkdate = async () => {
    try {
      const datechk = await axios({
        method: "get",
        url: `${request.erprequest}/products/${currentpdpdata?.sku}/delivery-date`,
        headers: {
          token: `Bearer ${localStorage.getItem("erpToken")}`,
        },
      });
      if (datechk.data.data[0].DELIVER_DATE[0].DD) {
        addToCart(datechk.data.data[0].DELIVER_DATE, currentOrder);
      }
    } catch (e) {
      console.log(e);
    }
  };

  // API Call to add the prodcut to the cart
  const addToCart = async (date, OT) => {
    let defaultaddress;
    addressdata?.find((data) => {
      if (data?.chk_default == "Y") {
        defaultaddress = data;
      }
    });
    const findsku = cart.find((c) => c.sku === currentpdpdata?.sku);
    let slicedate
    if (currentpdpdata?.order_taking === "N") {
      slicedate = date?.slice(parseInt(defaultaddress?.leadtime_days))
    } else {
      slicedate = date
    }
    const cunit = getcunit(currentpdpdata)

    if (cunit?.price === undefined)
      return toast.info(`${t("UNITPRICEVALIDATIONS")}`);
    if (localStorage.getItem("token") === null)
      return toast.error(
        `${t("CARTADDVALIDATION")}`
      );

    if (
      currentpdpdata?.product_status
      === "2" &&
      currentpdpdata?.is_in_stock == 0 && OT?.STATUS !== "O"
    )
      return toast.info(`${currentpdpdata?.name} is ${t("COMINGSOON")}`);
    if (
      (currentpdpdata?.product_status === "2" &&
        currentpdpdata?.is_in_stock === "1" &&
        parseInt(currentpdpdata?.qty).toFixed(0) >= 1) ||
      currentpdpdata?.product_status === "3" || OT?.STATUS === "O"
    ) {
      try {
        const deliveryDate = currentOrder?.DELIVER_DATE || (currentOrder?.DD ? currentOrder.DD : slicedate[0]?.DD);
        const formattedDate = moment(deliveryDate).format("YYYY-MM-DD");

        const cartdata = await axios({
          method: "post",
          url: request.cartadd,
          data: {
            cartItem: {
              sku: currentpdpdata?.sku,
              quote_id: localStorage.getItem("cartid"),
              qty: qtyRef.current,
              price: OT?.STATUS === "O" ? OT?.ORDER_PRICE : cunit.price,
              extension_attributes: {
                unit: OT?.STATUS === "O" ? OT?.ORDER_UNIT : cunit?.title,
              },
            },
            splitorder: {
              company_id: localStorage.getItem("companyid"),
              customer_id: localStorage.getItem("userid"),
              branch_id: defaultaddress?.address_id,
              product_id: pid,
              item_id: findsku?.item_id ? findsku?.item_id : 0,
              name: currentpdpdata?.name,
              sku: currentpdpdata?.sku,
              delivery_date: formattedDate,
              order_taking_request: specialText?.current?.value
            },
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        dispatch({
          type: "CART_STATUS",
          status: cartdata.status,
          delivery_date: OT?.DELIVER_DATE || formattedDate
        });
        if (cartdata?.data[0]?.success) {
          toast.success(
            `${currentpdpdata?.name} ${t("CARTADD")}`
          );
        } else {
          toast.info(cartdata?.data[0]?.message);
          // toast.info(``)
        }
      } catch (e) {
        // toast.error(e.response?.data?.message);
      }
    } else {
      return toast.info(`${currentpdpdata?.name} ${t("COMINGSOON")}`);
    }
  };
  const getcunit = (currentdata) => {
    const data = currentdata?.options[0]
    return { title: data?.order_type, price: data?.price }
  }

  // CURRENCT FORMAT FUNCTION
  function formatToCurrency(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const productStatusMemo = useMemo(() => {
    let data = ORDERTAKING__DATES__DATA?.find(el => el?.STATUS === "O")
    return currentpdpdata?.is_in_stock == 0 &&
      currentpdpdata?.product_status === "1" ? (
      <button
        type="button"
        className="btn add_tocart pdp__cart__disabled"
        disabled
        aria-labelledby='pdp__addcart'
      >
        {t("COMINGSOON")}
      </button>
    ) : (
      <>
        {currentpdpdata?.order_taking === "N" && (
          <button
            type="button"
            className="btn add_tocart rippleeffect"
            onClick={() => checkdate()}
          >
            {window.innerWidth > 912 ? <img src={carticon} alt="" /> : ""}
            &nbsp;{t("ADDTOCART")}
          </button>
        )}
        {!data && currentpdpdata?.product_status === "3" &&
          currentpdpdata?.order_taking_end_date !== "" && (
            <button
              type="button"
              className="btn add_tocart ripple-effect"
              onClick={() => checkdate()}
            >
              {window.innerWidth > 912 ? <img src={carticon} alt="" /> : ""}
              &nbsp;{t("ADD")}
            </button>
          )}
        {!data && currentpdpdata?.product_status
          === "2" &&
          currentpdpdata?.order_taking_end_date === "" && (
            <button
              type="button"
              className="btn add_tocart pdp__cart__disabled"
              disabled
            >
              {window.innerWidth > 912 ? <img src={carticon} alt="" /> : ""}
              &nbsp;{t("NOTAVAILABLE")}
            </button>
          )}
        {currentpdpdata?.order_taking === "Y" && data?.STATUS === "O" && <button
          type="button"
          className="btn add_tocart ripple-effect"
          onClick={() => checkdate()}
        >
          {window.innerWidth >= 912 ? <img src={carticon} alt="" /> : ""}
          &nbsp;{t("ADDTOCART")}
        </button>}
      </>
    )
  }, [currentpdpdata, t, currentOrder])

  // Add to cart button
  const AddToCartMemo = useMemo(() => {
    return <>
      {window.innerWidth > 912 ? (
        <div className={`priceDetails__cartAdd ${currentpdpdata.order_taking == "Y" && "ordertaking__cartadd"}`}>
          <div className='priceInfo__quantity'>
            <button onClick={subtractQuantity} aria-labelledby='decrement' id="decrement" title="decrement" aria-label="decrement">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </button>
            <input type='number'
              onChange={(e) => updateqty(Math.trunc(e.target.value))}
              value={qty > 0 && qty}
              aria-label="qtyinput"
              aria-labelledby="qtyinput"
            />
            {/* <span className='priceInfo__currentQTY'>{qty}</span> */}

            <button onClick={() => addQuantity()} aria-labelledby='increment' id="increment" title="increment" aria-label="increment">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </button>
          </div>
          {/* <button className='priceInfo__addCart'>{t("Add")}</button> */}
          {productStatusMemo}
        </div>)
        : (
          <div className='PDP__MOBILE__CARTADD'>
            {currentpdpdata?.favourite ? <img src={MobieFav} onClick={AddtoFav} /> : <img src={MobileFavRem} alt="" onClick={AddtoFav} />}
            <div className='priceInfo__quantity'>
              <button onClick={subtractQuantity} aria-labelledby='decrement' id="decrement" title="decrement" aria-label="decrement">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-dash" viewBox="0 0 16 16">
                  <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                </svg>
              </button>
              <input type='number'
                onChange={(e) => updateqty(Math.trunc(e.target.value))}
                value={qty > 0 && qty}
                aria-label="qtyinput"
                aria-labelledby="qtyinput"
              />
              {/* <span className='priceInfo__currentQTY'>{qty}</span> */}
              <button onClick={addQuantity} aria-labelledby='increment' id="increment" title="increment" aria-label="increment">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-plus" viewBox="0 0 16 16">
                  <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
              </button>
            </div>
            {/* <svg xmlns="http://www.w3.org/2000/svg" width="135" height="5" viewBox="0 0 135 5" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.5 2.5C0.5 1.11929 1.61929 0 3 0H132C133.381 0 134.5 1.11929 134.5 2.5C134.5 3.88071 133.381 5 132 5H3C1.61929 5 0.5 3.88071 0.5 2.5Z" fill="#18273A" fill-opacity="0.94" />
</svg> */}
            {productStatusMemo}
          </div>
        )}
    </>
  }, [currentpdpdata, qty, currentOrder, t])

  let foundFirstN = false;

  const priceInfoMemo = useMemo(() => {
    return <div className='pdpDetails__priceInfo'>
      {currentpdpdata?.order_taking === "Y" ?
        <div className='pdpDetails__orderCard'>
          <p className='pdpDetails__orderCard__header'>
            {window.innerWidth > 912 ? <span>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                <g clip-path="url(#clip0_145_34112)">
                  <path d="M20 8.5H17V4.5H3C1.9 4.5 1 5.4 1 6.5V17.5H3C3 19.16 4.34 20.5 6 20.5C7.66 20.5 9 19.16 9 17.5H15C15 19.16 16.34 20.5 18 20.5C19.66 20.5 21 19.16 21 17.5H23V12.5L20 8.5ZM19.5 10L21.46 12.5H17V10H19.5ZM6 18.5C5.45 18.5 5 18.05 5 17.5C5 16.95 5.45 16.5 6 16.5C6.55 16.5 7 16.95 7 17.5C7 18.05 6.55 18.5 6 18.5ZM8.22 15.5C7.67 14.89 6.89 14.5 6 14.5C5.11 14.5 4.33 14.89 3.78 15.5H3V6.5H15V15.5H8.22ZM18 18.5C17.45 18.5 17 18.05 17 17.5C17 16.95 17.45 16.5 18 16.5C18.55 16.5 19 16.95 19 17.5C19 18.05 18.55 18.5 18 18.5Z" fill="#323232" />
                </g>
                <defs>
                  <clipPath id="clip0_145_34112">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>
              <span>{t("PDPTEXTONE")}</span>
            </span> :
              <span>
                <p>
                  <img src={timer} alt="" />
                  <span>{t("HURRYUP")}</span>
                </p>
                <span>{t("PDPTEXTTWO")}</span>
              </span>
            }
            <Timer data={ORDERTAKING__DATES__DATA?.[OTSelection]} />
          </p>
          <div className='PDP__OC'>
            {ORDERTAKING__DATES__DATA?.map((data, i) => (
              <React.Fragment key={i}>
                {/* Display all content */}
                {data.STATUS === "O" && (
                  <OrderingCards data={data} OrderTakingSelection={OrderTakingSelection} index={i} OTSelection={OTSelection} />
                )}
              </React.Fragment>
            ))}
            {
              ORDERTAKING__DATES__DATA?.map((data, i) => (
                !foundFirstN && data.STATUS === "N" ? (
                  <React.Fragment key={i}>
                    <OrderingCards
                      data={data}
                      OrderTakingSelection={OrderTakingSelection}
                      index={i}
                      OTSelection={OTSelection}
                    />
                    {foundFirstN = true} {/* Set foundFirstN to true after rendering the first match */}
                  </React.Fragment>
                ) : null
              ))
            }

          </div>
          {window.innerWidth <= 912 ?
            <div className='PLP__MOBILE__PRICE'>
              <span className='PLP__MOBILE__APPROXTEXT'>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#1C304A" fill-opacity="0.52" />
                </svg>
                {t("Approximate Price")}
              </span>
              <p>
                {currentpdpdata?.order_taking == "Y" ?
                  currentOrder !== undefined ?
                    <>
                      {/* {t("Approximate Price")} */}
                      <span className='info__currency'>฿</span>{(formatToCurrency(parseFloat(currentOrder?.ORDER_PRICE).toFixed(2)))}
                      <span> /{currentOrder?.ORDER_UNIT}</span>
                      <div className='pdp_addtocart_block_mobile'>
                        {AddToCartMemo}
                      </div>
                    </>
                    :
                    <>
                      <span className='info__currency'>฿</span>{(currentpdpdata?.options && formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2)))}
                      <span> /{currentpdpdata?.options && t(currentpdpdata?.options[0]?.order_type)}</span>
                    </> : null
                }
                {currentpdpdata?.order_taking == "N" ?
                  <>
                    <span className='info__currency'>฿</span>{(formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2)))}
                    <span> /{currentpdpdata?.options[0]?.order_type}</span>
                    <span className='info__unitprice'>
                      ({`${t("UNITPRICE")}: ฿${currentpdpdata?.selling_unit_price && formatToCurrency(parseFloat(currentpdpdata?.selling_unit_price).toFixed(2))} / ${t(currentpdpdata?.selling_unit)}`})
                    </span>
                  </>
                  : null}
              </p>
            </div>
            : null}
          <div className='PLP__SPECIALREQUEST'>
            <label>{t("PDPTEXTTHREE")}</label>
            <input type='text' ref={specialText} placeholder='eg. approximate weight for this order' />
          </div>
        </div>
        :
        window.innerWidth <= 912 ? <div className='MOBILE__priceInfo__info'>{/* for stock product in mobile */}
          {currentpdpdata && currentpdpdata.length != 0 ?
            <>
              <>
                <div className='pdpDetails__orderCard'>
                  <p className='pdpDetails__orderCard__header'>
                    <span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                        <g clip-path="url(#clip0_145_34112)">
                          <path d="M20 8.5H17V4.5H3C1.9 4.5 1 5.4 1 6.5V17.5H3C3 19.16 4.34 20.5 6 20.5C7.66 20.5 9 19.16 9 17.5H15C15 19.16 16.34 20.5 18 20.5C19.66 20.5 21 19.16 21 17.5H23V12.5L20 8.5ZM19.5 10L21.46 12.5H17V10H19.5ZM6 18.5C5.45 18.5 5 18.05 5 17.5C5 16.95 5.45 16.5 6 16.5C6.55 16.5 7 16.95 7 17.5C7 18.05 6.55 18.5 6 18.5ZM8.22 15.5C7.67 14.89 6.89 14.5 6 14.5C5.11 14.5 4.33 14.89 3.78 15.5H3V6.5H15V15.5H8.22ZM18 18.5C17.45 18.5 17 18.05 17 17.5C17 16.95 17.45 16.5 18 16.5C18.55 16.5 19 16.95 19 17.5C19 18.05 18.55 18.5 18 18.5Z" fill="#323232" />
                        </g>
                        <defs>
                          <clipPath id="clip0_145_34112">
                            <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                          </clipPath>
                        </defs>
                      </svg>
                      <span>{t("PDPTEXTONE")}</span>
                    </span>
                  </p>
                  <div className='PDP__OC_container'>
                    <div className='PDP__OC'>
                      {/* Here need to add stock product dates - mobile */}
                      {ORDERTAKING__DATES__DATA?.map((data, i) => (
                        i < 6 && (<React.Fragment key={i}>
                          {/* Display all content */}
                          <StockProductDateCards
                          key={`stockProductDateCard-${i}`} // Assigning a unique key
                          data={data} OrderTakingSelection={OrderTakingSelection} index={i} OTSelection={OTSelection} currentOrder={currentOrder} branchAddresses={branchAddresses} defaultBranchId={defaultBranchId} />
                        </React.Fragment>)
                      ))}

                    </div>
                  </div>
                  <div className='PLP__SPECIALREQUEST'>
                    {/* <label>{t("PDPTEXTTHREE")}</label>
                    <input type='text' ref={specialText} placeholder='eg. approximate weight for this order' /> */}
                  </div>
                </div>
                <div>
                  {/* <span className='info__currency'>฿</span>{(formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2)))}
                  <span> /{currentpdpdata?.options[0]?.order_type}</span>
                  <span className='info__unitprice'>
                    ({`${t("UNITPRICE")}: ฿${currentpdpdata?.selling_unit_price && formatToCurrency(parseFloat(currentpdpdata?.selling_unit_price).toFixed(2))} / ${t(currentpdpdata?.selling_unit)}`})
                  </span> */}
                </div>
              </>
              <p>
                <span className='info__currency'>฿</span>{currentpdpdata?.options && (formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2)))}
                <span> /{currentpdpdata?.options && currentpdpdata?.options[0]?.order_type}</span>
                <span className='info__unitprice'>
                  ({`${t("UNITPRICE")}: ฿${currentpdpdata?.selling_unit_price && formatToCurrency(parseFloat(currentpdpdata?.selling_unit_price).toFixed(2))} / ${t(currentpdpdata?.selling_unit)}`})
                </span>
              </p>
              <p className='PLP__MOBILE__APPROXTEXT'>
                {page === "product" ? <div className="pdpDetails__priceexpiry">
                  {t("Price Valid till")}:{" "}
                  {moment(currentpdpdata?.expired_at).format("YYYY-MM-DD")}
                </div> : ""}
                <span><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#EE4D44" />
                </svg>
                  {t("Approximate Price")}
                </span>
              </p>
              {/* stock product mobile */}
              <div className='pdp_addtocart_block_mobile'>
                {AddToCartMemo}
              </div>
            </> : null}
        </div> :
          null
      }

      {window.innerWidth > 912 ? <div className='priceInfo__info'>
        <p>
          {currentpdpdata?.order_taking == "Y" ?
            currentOrder !== undefined ?
              <>
                <div className='pdp_price_addtocart_parent'>
                  <div className='pdp_price_block'>
                    <span className='info__currency'>฿</span>{(formatToCurrency(parseFloat(currentOrder?.ORDER_PRICE).toFixed(2)))}
                    <span> /{currentOrder?.ORDER_UNIT}</span>
                  </div>
                  {/*order taking web*/}
                  <div className='pdp_addtocart_block'>
                    {AddToCartMemo}
                  </div>
                </div>
              </>
              :
              <>
                <span className='info__currency'>฿</span>{(currentpdpdata?.options && formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2)))}
                <span> /{currentpdpdata?.options && t(currentpdpdata?.options[0]?.order_type)}</span>
              </> : null
          }
          {currentpdpdata?.order_taking == "N" ?
            <>
              <div className='pdpDetails__orderCard'>
                <p className='pdpDetails__orderCard__header'>
                  {window.innerWidth > 912 ? <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                      <g clip-path="url(#clip0_145_34112)">
                        <path d="M20 8.5H17V4.5H3C1.9 4.5 1 5.4 1 6.5V17.5H3C3 19.16 4.34 20.5 6 20.5C7.66 20.5 9 19.16 9 17.5H15C15 19.16 16.34 20.5 18 20.5C19.66 20.5 21 19.16 21 17.5H23V12.5L20 8.5ZM19.5 10L21.46 12.5H17V10H19.5ZM6 18.5C5.45 18.5 5 18.05 5 17.5C5 16.95 5.45 16.5 6 16.5C6.55 16.5 7 16.95 7 17.5C7 18.05 6.55 18.5 6 18.5ZM8.22 15.5C7.67 14.89 6.89 14.5 6 14.5C5.11 14.5 4.33 14.89 3.78 15.5H3V6.5H15V15.5H8.22ZM18 18.5C17.45 18.5 17 18.05 17 17.5C17 16.95 17.45 16.5 18 16.5C18.55 16.5 19 16.95 19 17.5C19 18.05 18.55 18.5 18 18.5Z" fill="#323232" />
                      </g>
                      <defs>
                        <clipPath id="clip0_145_34112">
                          <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>{t("PDPTEXTONE")}</span>
                  </span> :
                    <span>
                      <p>
                        <img src={timer} alt="" />
                        <span>{t("HURRYUP")}</span>
                      </p>
                      <span>{t("PDPTEXTTWO")}</span>
                    </span>
                  }
                </p>
                <div className='PDP__OC_container'>
                  <div className='PDP__OC'>
                    {ORDERTAKING__DATES__DATA?.map((data, i) => (
                      i < 6 && (<React.Fragment key={i}>
                        {/* Display all content */}
                        <StockProductDateCards
                          key={`stockProductDateCard-${i}`} // Assigning a unique key
                          data={data} OrderTakingSelection={OrderTakingSelection} index={i} OTSelection={OTSelection} currentOrder={currentOrder} branchAddresses={branchAddresses} defaultBranchId={defaultBranchId} />
                      </React.Fragment>)
                    ))}

                    {/* Here need to add stock product dates - web */}

                  </div>
                </div>
                <div className='PLP__SPECIALREQUEST'>
                  {/* <label>{t("PDPTEXTTHREE")}</label>
                  <input type='text' ref={specialText} placeholder='eg. approximate weight for this order' /> */}
                </div>
              </div>
              <div className='pdp_price_addtocart_parent'>
                <div className='pdp_price_block'>
                  <span className='info__currency'>฿</span>{(formatToCurrency(parseFloat(currentpdpdata?.options[0]?.price).toFixed(2)))}
                  <span> /{currentpdpdata?.options[0]?.order_type}</span>
                  <span className='info__unitprice'>
                    ({`${t("UNITPRICE")}: ฿${currentpdpdata?.selling_unit_price && formatToCurrency(parseFloat(currentpdpdata?.selling_unit_price).toFixed(2))} / ${t(currentpdpdata?.selling_unit)}`})
                  </span>
                </div>
                {/* stock product web */}
                <div className='pdp_addtocart_block'>
                  {AddToCartMemo}
                </div>
              </div>
            </>
            : null}
        </p>
      </div> : null
      }

      {
        window.innerWidth > 912 ? <div className='pdpDetails__quoteInfo'>
          {page === "product" ? <div className="pdpDetails__priceexpiry">
            ({t("Price Valid till")}:{" "}
            {moment(currentpdpdata?.expired_at).format("YYYY-MM-DD")})
          </div> : ""}
          <span class={`pdpDetails__approxPrice ${currentpdpdata?.order_taking == "Y" && "approxPrice__orderTaking"}`}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M11 15H13V17H11V15ZM11 7H13V13H11V7ZM11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#EE4D44" />
          </svg> {t("Approximate Price")}</span>
        </div> : null
      }
    </div >

  }, [currentpdpdata, t, ORDERTAKING__DATES__DATA, OTSelection, currentOrder, qty])

  const unitPriceMemo = useMemo(() => {
    return <div className='pdpDetails__unitprice'>
      <div className='unitPrice__content'>
        <p>
          <span className='pdpDetails__unitprice--label'>{t("Brand")} {t("NAME")}:</span>
          <span className='pdpDetails__unitprice--value' title={currentpdpdata?.brand_name}>{currentpdpdata?.brand_name}</span>
        </p>
        <p>
          <span className='pdpDetails__unitprice--label'>{t("PACKAGESIZE")}:</span>
          <span className='pdpDetails__unitprice--value' title={currentpdpdata?.pack_size}>{currentpdpdata?.pack_size}</span>
        </p>
        <p>
          <span className='pdpDetails__unitprice--label'>{t("STORAGEMETHOD")}:</span>
          <span className='pdpDetails__unitprice--value' title={currentpdpdata?.storage}>{currentpdpdata?.storage}</span>
        </p>
      </div>
    </div>
  }, [currentpdpdata, t])

  const moreInfoMemo = useMemo(() => {
    return <MoreInfo data={currentpdpdata} seasonsData={
      <div className='PDP__SEASONS'>
        <h5>{t("PDPTEXTFOUR")}</h5>
        <small>{t("PDPTEXTFIVE")}</small>
        <p className='SEASON__DATA'>
          {SEASONS__DATA__LIST?.map(el => <span key={el.id}
            className={
              `${el?.SEASON_FLAG == "G" && "SEASON__HIGH"} ${el?.SEASON_FLAG == "Y" && "SEASON__LOW"}`
            }
          >
            {el?.SEASON_FROM == "1" && "Jan"}
            {el?.SEASON_FROM == "2" && "Feb"}
            {el?.SEASON_FROM == "3" && "Mar"}
            {el?.SEASON_FROM == "4" && "Apr"}
            {el?.SEASON_FROM == "5" && "May"}
            {el?.SEASON_FROM == "6" && "Jun"}
            {el?.SEASON_FROM == "7" && "Jul"}
            {el?.SEASON_FROM == "8" && "Aug"}
            {el?.SEASON_FROM == "9" && "Sep"}
            {el?.SEASON_FROM == "10" && "Oct"}
            {el?.SEASON_FROM == "11" && "Nov"}
            {el?.SEASON_FROM == "12" && "Dec"}
          </span>)}
        </p>
        <p className='SEASONS__AVAILABLE'>
          <span className='SEASON__HIGHAVAILABLE'>{t("HIGHSEASON")}</span>
          <span className='SEASON__LOWAVAILABLE'>{t("LOWSEASON")}</span>
          <span className='SEASON__NOTAVAILABLE'>{t("NOTSEASON")}</span>
        </p>
      </div>} />
  }, [currentpdpdata, t, SEASONS__DATA__LIST])
  const [view, setView] = useState(false);


  // FAVOURITES HANDLER
  const addToFavHandler = () => {
    const ctoken = localStorage.getItem("token");
    if (ctoken === null || ctoken === "" || ctoken === undefined) {
      setView(false);
      toast.warning(
        `${t("Please Login to add/Remove the product to Favourites.")}`
      );
    } else {
      let message = `${currentpdpdata?.name} ${t("Added to the Favourite Successfully")}`
      ADDFAV(dispatch, pid, setView, toast, message, toast)
    }
  };

  return (
    <div className='pdpDetails'>

      {window.innerWidth > 912 ?
        <>
          <h5 className='pdpDetails__name' title={currentpdpdata?.name}>{currentpdpdata && (currentpdpdata?.name)}</h5>

          <div className='pdpdetails__quotesku'>
            {window.innerWidth < 912 && <span>{currentpdpdata?.sku}</span>}
            {mouseEnter ? <span className='plpDetails__quotemsg'>{t("Look for bulk purchase? Please request quotation here.")}</span> : null}
            <p>{t("SKU")} : {currentpdpdata?.sku}</p>
            {page !== "product" && currentpdpdata?.order_taking == "N" ?
              <RequestQatation
                className="pdpDetails__quotationButton"
                setMouseEnter={setMouseEnter}
                units={currentpdpdata?.selling_unit}
                price={currentpdpdata?.selling_unit_price}
                pid={pid}
                status={currentpdpdata?.quote_status}
                setrefreshpdp={setrefreshpdp}
                old_quote_price={currentpdpdata?.old_quote_price}
              /> : null}
          </div></> : ""}

      {window.innerWidth > 912 ? unitPriceMemo : currentpdpdata?.order_taking === "N" && <p>
        <span className='pdpDetails__unitprice--label'>{t("SIZE")}: {" "}</span>
        <span className='pdpDetails__unitprice--value' title={currentpdpdata?.pack_size}>{currentpdpdata?.pack_size}</span>
      </p>}

      {priceInfoMemo}

      <span className='line'></span>

      {moreInfoMemo}
    </div>
  )
}

export default PDPDetails