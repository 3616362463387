import React, { useEffect, useState } from 'react'
import './PDPImageCarousel.scss'
import Slider from "react-slick";
import favoriteactive from "../../../../assets/images/card/favorite.svg";
import favoriteinactive from "../../../../assets/images/card/favorite_border.svg";
import wishlistinactive from "../../../../assets/images/card/note.png";
import wishlistactive from "../../../../assets/images/card/note_border.jpg";
import { useTranslation } from "react-i18next";
import { toast } from 'react-toastify';
import { useStateValue } from '../../../../store/state';
import { ADDFAV, ADDTOWISH, DELETEFAV, DELETEFROMWISH, PDPIMAGES } from '../../../../store/PDP/Action';

function PDPImageCarousel({ sku, pid, currentpdpdata, setrefreshpdp }) {
  // const [mouseEnter, setMouseEnter] = useState(false)
  const [currentImage, setCurrentImage] = useState()
  const [allImages, setAllImages] = useState([])
  const [view, setView] = useState(false);
  const [fav, setFav] = useState(false);
  const { t } = useTranslation();
  const [{ PDP__IMAGES__DATA }, dispatch] = useStateValue();

  // NEXT ARROW EVENT AND ICON
  const NextArrow = ({ onClick }) => {
    return (
      <div className="pdpimagecarousel__nextArrow" style={{ right: "-16px" }} onClick={onClick}>
        <i className="fa fa-angle-right"></i>
      </div>
    );
  };

  // NEXT PREVIOUS EVENT AND ICON
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="pdpimagecarousel__prevArrow" style={{ left: "-16px" }} onClick={onClick}>
        <i className="fa fa-angle-left"></i>
      </div>
    );
  };

  // CAROUSEL SETTINGS
  const settings = {
    dots: window.innerWidth > 912 ? false : true,
    infinite: false,
    speed: 500,
    slidesToShow: window.innerWidth > 912 ? 5 : 1,
    slidesToScroll: window.innerWidth > 912 ? 5 : 1,
    initialSlide: 1,
    nextArrow: window.innerWidth > 912 ? <NextArrow onClick /> : "",
    prevArrow: window.innerWidth > 912 ? <PrevArrow onClick /> : "",
  };

  useEffect(() => {
    setAllImages(PDP__IMAGES__DATA)
    setCurrentImage(PDP__IMAGES__DATA[0])
  }, [PDP__IMAGES__DATA])

  useEffect(() => {
    PDPIMAGES(dispatch, sku)
  }, [sku])

  // ADDING/DELETING TO FAVOURITES HANDLER
  const addToView = () => {
    if (view === true) {
      let message = `${currentpdpdata?.name} ${t("FAVREMOVED")}`
      // deletefromFav();
      DELETEFAV(dispatch, pid, view, setView, toast, message, setrefreshpdp);
    } else {
      addToFavHandler();
      setrefreshpdp(true)
    }
  };

  // FAVOURITES HANDLER
  const addToFavHandler = () => {
    const ctoken = localStorage.getItem("token");
    if (ctoken === null || ctoken === "" || ctoken === undefined) {
      setView(false);
      toast.warning(
        `${t("Please Login to add/Remove the product to Favourites.")}`
      );
    } else {
      let message = `${currentpdpdata?.name} ${t("FAVADDED")}`
      ADDFAV(dispatch, pid, setView, toast, message, toast)
    }
  };

  // ADDING/DELETING TO WISHLIST HANDLER
  const Fav = () => {
    if (fav === true) {
      let message = `${currentpdpdata.name} ${t("WISHREMOVED")}`
      DELETEFROMWISH(dispatch, pid, setFav, message, setrefreshpdp, fav, toast)
    } else {
      addToWishHandler();
      setrefreshpdp(true)
    }
  };

  // WISHLIST HANDLER
  const addToWishHandler = () => {
    const ctoken = localStorage.getItem("token");
    if (ctoken === null || ctoken === "" || ctoken === undefined) {
      toast.warning(
        `${t("Please Login to add/Remove the product to Wishlists.")}`
      );
    } else {
      let message = `${currentpdpdata.name} ${t("WISHADDED")}`
      ADDTOWISH(dispatch, pid, setFav, message, toast)
    }
  };

  useEffect(() => {
    setFav(currentpdpdata?.wish);
    setView(currentpdpdata?.favourite)
  }, [currentpdpdata])

  return (
    <div className='pdpimagecarousel'>
      {/* MAIN IMAGE */}
      {window.innerWidth > 912 ? <img src={currentImage?.URL} alt="" /> : ""}

      {/* CAROUSEL IMAGE */}

      {window.innerWidth > 912 ?
        <Slider {...settings}>
          {allImages?.map((AI, index) =>
            <img src={AI?.URL} alt="" key={index} className={`pdpimagecarousel__slider ${currentImage?.URL === AI?.URL ? "pdpimagecarousel__selected" : ""}`} onMouseOver={() => setCurrentImage(AI)} />
          )}
        </Slider>
        :
        <div className='MOBILE__IMAGE'>
          <Slider {...settings}>
            {allImages?.map((AI, index) =>
              <img src={AI?.URL} alt="" key={index} />
            )}
          </Slider>
          {currentpdpdata?.wish ? <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 24 24" fill="none" onClick={Fav}>
            <g clip-path="url(#clip0_2167_31247)">
              <path d="M4.40654 0.637509C4.20498 0.726571 4.08779 0.829696 3.99873 1.00313C3.90967 1.17188 3.90498 1.53751 3.98467 1.6922C4.12529 1.9547 5.12373 3.27657 5.24092 3.35626C5.43779 3.48751 5.80342 3.49688 6.03311 3.3797C6.37998 3.20626 6.55811 2.73282 6.40811 2.39063C6.37061 2.30157 6.09873 1.89845 5.80342 1.50001C5.36748 0.904696 5.23623 0.754696 5.06279 0.665634C4.82373 0.543759 4.63623 0.539071 4.40654 0.637509Z" fill="#FFC700" />
              <path d="M18.9375 0.665597C18.7641 0.75466 18.6281 0.90466 18.1547 1.56091C17.7984 2.0531 17.5688 2.40935 17.55 2.51247C17.4516 3.04685 17.8547 3.50622 18.3938 3.45935C18.525 3.44997 18.6891 3.4031 18.7594 3.35622C18.8766 3.27653 19.875 1.95466 20.0156 1.69216C20.0953 1.53747 20.0906 1.17185 20.0016 1.0031C19.9078 0.824972 19.7953 0.721847 19.5797 0.632785C19.3453 0.534347 19.1813 0.543722 18.9375 0.665597Z" fill="#FFC700" />
              <path d="M11.4611 1.88909C11.1376 1.9969 10.908 2.14221 10.6736 2.37659C10.4861 2.56878 10.2986 2.91096 9.49702 4.52815L8.54546 6.44534L6.79234 6.69846C4.45796 7.03128 4.29859 7.0594 4.02671 7.20003C3.34234 7.54221 2.95796 8.22659 3.01421 9.00471C3.06109 9.68909 3.07984 9.71253 4.85171 11.461L6.42671 13.0078L6.07046 15.0563C5.87827 16.1813 5.7189 17.1844 5.7189 17.2828C5.7189 17.5641 5.85015 18 6.00484 18.2438C6.42202 18.8907 7.23765 19.2375 7.9689 19.0828C8.13765 19.0453 8.85015 18.6985 10.0736 18.0563C11.0908 17.5219 11.9486 17.0813 11.9767 17.0719C12.0048 17.0672 12.872 17.5032 13.9033 18.0422C15.1923 18.7219 15.8533 19.0453 16.0314 19.0828C17.1517 19.3172 18.272 18.4219 18.2814 17.2828C18.2814 17.1844 18.122 16.1813 17.9298 15.0563L17.5736 13.0078L19.1486 11.461C20.9298 9.70315 20.9439 9.68909 20.9861 8.97659C21.033 8.21721 20.6486 7.54221 19.9689 7.19534C19.697 7.0594 19.5048 7.0219 17.208 6.69378L15.4548 6.44534L14.4986 4.52346C13.6642 2.84065 13.5142 2.57346 13.3033 2.35784C12.9564 2.00628 12.6048 1.86096 12.0705 1.84221C11.808 1.83284 11.5783 1.85159 11.4611 1.88909Z" fill="#FFC700" />
              <path d="M1.31732 13.7578C0.356387 14.0719 0.197012 14.1656 0.0657617 14.4703C-0.145176 14.9578 0.243887 15.5578 0.768887 15.5625C0.975137 15.5625 2.51264 15.0844 2.77982 14.9391C3.21107 14.7 3.30014 14.1 2.96264 13.7156C2.85014 13.5937 2.53607 13.4531 2.36732 13.4578C2.29232 13.4625 1.81889 13.5937 1.31732 13.7578Z" fill="#FFC700" />
              <path d="M21.3047 13.5422C21.2016 13.5891 21.0797 13.6687 21.0375 13.7156C20.925 13.8422 20.8125 14.1281 20.8125 14.2828C20.8125 14.5078 20.9906 14.8031 21.2016 14.925C21.4734 15.075 23.0156 15.5625 23.2313 15.5625C23.4891 15.5625 23.7844 15.3703 23.9016 15.1266C24.0656 14.7844 24.0188 14.4891 23.7516 14.2125C23.625 14.0812 23.4609 14.0109 22.6641 13.7578C22.1438 13.5891 21.6703 13.4531 21.6094 13.4531C21.5438 13.4531 21.4078 13.4953 21.3047 13.5422Z" fill="#FFC700" />
              <path d="M11.7469 20.25C11.6719 20.2735 11.55 20.3532 11.4703 20.4235C11.2219 20.6578 11.2031 20.7657 11.2031 21.8297C11.2031 22.7016 11.2125 22.8188 11.2969 22.9875C11.5594 23.5266 12.3187 23.5782 12.6562 23.0766C12.7687 22.9078 12.7734 22.8844 12.7875 21.9188C12.7969 21.3235 12.7828 20.8641 12.7547 20.7657C12.6703 20.475 12.2812 20.1938 11.9812 20.2078C11.925 20.2078 11.8219 20.2313 11.7469 20.25Z" fill="#FFC700" />
            </g>
            <defs>
              <clipPath id="clip0_2167_31247">
                <rect width="24" height="24" fill="white" />
              </clipPath>
            </defs>
          </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 32 32" fill="none" onClick={Fav}>
            <g clip-path="url(#clip0_2167_31449)">
              <path d="M5.87507 0.849971C5.60632 0.968721 5.45007 1.10622 5.33132 1.33747C5.21257 1.56247 5.20632 2.04997 5.31257 2.25622C5.50007 2.60622 6.83131 4.36872 6.98756 4.47497C7.25006 4.64997 7.73756 4.66247 8.04381 4.50622C8.50631 4.27497 8.74381 3.64372 8.54382 3.18747C8.49381 3.06872 8.13131 2.53122 7.73756 1.99997C7.15631 1.20622 6.98132 1.00622 6.75007 0.887471C6.43132 0.724971 6.18132 0.718721 5.87507 0.849971Z" fill="white" />
              <path d="M25.2502 0.887544C25.0189 1.00629 24.8377 1.20629 24.2064 2.08129C23.7314 2.73754 23.4252 3.21254 23.4002 3.35004C23.2689 4.06254 23.8064 4.67504 24.5252 4.61254C24.7002 4.60004 24.9189 4.53754 25.0127 4.47504C25.1689 4.36879 26.5002 2.60629 26.6877 2.25629C26.7939 2.05004 26.7877 1.56254 26.6689 1.33754C26.5439 1.10004 26.3939 0.962544 26.1064 0.843794C25.7939 0.712544 25.5752 0.725044 25.2502 0.887544Z" fill="white" />
              <path d="M15.2814 2.51874C14.8502 2.66249 14.5439 2.85624 14.2314 3.16874C13.9814 3.42499 13.7314 3.88124 12.6627 6.03749L11.3939 8.59374L9.05645 8.93124C5.94395 9.37499 5.73145 9.41249 5.36895 9.59999C4.45645 10.0562 3.94395 10.9687 4.01895 12.0062C4.08145 12.9187 4.10645 12.95 6.46895 15.2812L8.56895 17.3437L8.09395 20.075C7.8377 21.575 7.6252 22.9125 7.6252 23.0437C7.6252 23.4187 7.8002 24 8.00645 24.325C8.5627 25.1875 9.6502 25.65 10.6252 25.4437C10.8502 25.3937 11.8002 24.9312 13.4314 24.075C14.7877 23.3625 15.9314 22.775 15.9689 22.7625C16.0064 22.7562 17.1627 23.3375 18.5377 24.0562C20.2564 24.9625 21.1377 25.3937 21.3752 25.4437C22.8689 25.7562 24.3627 24.5625 24.3752 23.0437C24.3752 22.9125 24.1627 21.575 23.9064 20.075L23.4314 17.3437L25.5314 15.2812C27.9064 12.9375 27.9252 12.9187 27.9814 11.9687C28.0439 10.9562 27.5314 10.0562 26.6252 9.59374C26.2627 9.41249 26.0064 9.36249 22.9439 8.92499L20.6064 8.59374L19.3314 6.03124C18.2189 3.78749 18.0189 3.43124 17.7377 3.14374C17.2752 2.67499 16.8064 2.48124 16.0939 2.45624C15.7439 2.44374 15.4377 2.46874 15.2814 2.51874Z" fill="white" />
              <path d="M1.75643 18.3438C0.475182 18.7625 0.262682 18.8875 0.0876823 19.2937C-0.193568 19.9437 0.325182 20.7437 1.02518 20.75C1.30018 20.75 3.35018 20.1125 3.70643 19.9187C4.28143 19.6 4.40018 18.8 3.95018 18.2875C3.80018 18.125 3.38143 17.9375 3.15643 17.9437C3.05643 17.95 2.42518 18.125 1.75643 18.3438Z" fill="white" />
              <path d="M28.4062 18.0562C28.2688 18.1187 28.1063 18.225 28.05 18.2875C27.9 18.4562 27.75 18.8375 27.75 19.0437C27.75 19.3438 27.9875 19.7375 28.2688 19.9C28.6313 20.1 30.6875 20.75 30.975 20.75C31.3188 20.75 31.7125 20.4938 31.8688 20.1687C32.0875 19.7125 32.025 19.3188 31.6688 18.95C31.5 18.775 31.2812 18.6812 30.2188 18.3438C29.525 18.1187 28.8938 17.9375 28.8125 17.9375C28.725 17.9375 28.5437 17.9937 28.4062 18.0562Z" fill="white" />
              <path d="M15.6625 27C15.5625 27.0312 15.4 27.1375 15.2937 27.2312C14.9625 27.5437 14.9375 27.6875 14.9375 29.1062C14.9375 30.2687 14.95 30.425 15.0625 30.65C15.4125 31.3687 16.425 31.4375 16.875 30.7687C17.025 30.5437 17.0312 30.5125 17.05 29.225C17.0625 28.4312 17.0437 27.8187 17.0062 27.6875C16.8937 27.3 16.375 26.925 15.975 26.9437C15.9 26.9437 15.7625 26.975 15.6625 27Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_2167_31449">
                <rect width="32" height="32" fill="white" />
              </clipPath>
            </defs>
          </svg>}
        </div>
      }

      {/* WISHLIST/FAVOURITE ICONS */}
      {window.innerWidth > 912 ?
        <span className="pdpimagecarousel__wishfav">
          <p
            onClick={Fav}
            className="favorite"
          >
            {currentpdpdata?.wish ? (
              <img src={wishlistactive} alt="" />
            ) : (
              <img src={wishlistinactive} alt="" />
            )}
            <span>{t("ADDTOWISH")}</span>
          </p>
          <p
            onClick={addToView}
            className="view"
          >
            {currentpdpdata?.favourite ? (
              <img src={favoriteactive} alt="" />
            ) : (
              <img src={favoriteinactive} alt="" />
            )}
            <span>{t("ADDTOFAV")}</span>
          </p>
        </span>
        : ""}
    </div>
  )
}

export default PDPImageCarousel