import React, { useEffect, useState, lazy, Suspense } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import FormInput from "../../components/formInput/FormInput";
import googleicon from "../../assets/images/Login_imgs/google.png";
import lineicon from "../../assets/images/Login_imgs/line.png";
import "./loginPage.styles.scss";
import logo from "../../assets/images/header/jagotawhite.webp";
import PasswordField from "../../components/formInput/PasswordField/PasswordField";
import { toast } from "react-toastify";
import { useStateValue } from "../../store/state";
import { Modal as RModal } from "react-responsive-modal";
import { injectStyle } from "react-toastify/dist/inject-style";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";
import English from "../../assets/images/home/eng1.png";
import Thai from "../../assets/images/home/thai1.png";
import { AGREETERMS, CHECKTERMS, FORGOTPASSWORD, LOGINUSERINFO } from "../../store/Login/Action";
import { browserName, browserVersion } from 'react-device-detect';
import LoginComponent from "../../components/LoginComponent/LoginComponent";
import ForgotPassword from "../ForgotPassword/ForgotPassword";
import ResetPassword from "../ResetPassword/ResetPassword";

const ChangePassword = lazy(() => import("../changePassword/ChangePassword"));
if (typeof window !== "undefined") {
  injectStyle();
}

function LoginPage() {
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");
  const [alert, setAlert] = useState(false);
  const history = useHistory();
  const [checkterms, setcheckterms] = useState(false)
  const [chooselang, setChooseLang] = useState(() => {
    const storedLanguage = localStorage.getItem('storeid')
    return storedLanguage ? parseInt(storedLanguage, 10) : 2; // Default to 2 if not found
  });

  const [checkingTerms, setCheckingTerms] = useState(false)
  const [emailModal, setEmailModal] = useState(false)
  const [emailModalValidation, setEmailModalValidation] = useState("")
  const [getEmail, setGetEmail] = useState("")
  const [{ }, dispatch] = useStateValue();

  const { id, tok } = useParams()

  // const [isChecked, setIsChecked] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');

  // const handleCheckboxChange = (option) => {
  //   setIsChecked(!isChecked);
  // };

  const currenturl = window.location.origin;

  const privacyPolicy = currenturl + '/CMS/PrivacyPolicy';
  const termsConditions = currenturl + '/CMS/Terms&Conditions';

  function openPopup(url) {
    window.open(url, '_blank', 'width=600,height=400');
  }

  // TERMS API
  useEffect(() => {
    CHECKTERMS(dispatch, localStorage.getItem('userid'), history, setShow)
  }, [checkterms]);

  const [errors, seterrors] = useState({
    username: "",
    password: "",
    // isChecked: "",
  });

  // Reapply error messages in the current language
  useEffect(() => {
    seterrors((prevErrors) => ({
      username: prevErrors?.username ? t("Please enter your username") : "",
      password: prevErrors?.password ? t("Please enter your password") : "",
      // isChecked: prevErrors?.isChecked ? t("By continuing, I agree to Privacy Policy and Terms of Use.") : "",
    }));
  }, [chooselang, t]);


  // LOGIN SUBMIT HANDLER AND LOGIN API CALL
  const SubmitHandler = async (e, val) => {
    e.preventDefault();
    if (username.length === 0) {
      seterrors((data) => ({
        ...data,
        username: t("Please enter your username"),
      }));
    } else {
      seterrors((data) => ({
        ...data,
        username: "",
      }));
    }

    if (password.length === 0) {
      seterrors((data) => ({
        ...data,
        password: t("Please enter your password"),
      }));
    } else {
      seterrors((data) => ({
        ...data,
        password: "",
      }));
    }

    // if (!isChecked) {
    //   seterrors((data) => ({
    //     ...data,
    //     isChecked: t("By continuing, I agree to Privacy Policy and Terms of Use."),
    //   }));
    // } else {
    //   seterrors((data) => ({
    //     ...data,
    //     isChecked: "",
    //   }));
    // }

    if (username?.length === 0 || password?.length === 0) return;
    LOGINUSERINFO(dispatch, username, password, toast, checkterms, setcheckterms)
  };

  const termsCheck = (value) => {
    setCheckingTerms(value)
  }

  useEffect(() => {
    if (emailModal) {
      setGetEmail("")
      setEmailModalValidation("")
    }
  }, [emailModal])

  const forgotEnterKey = (e) => {
    if (e.key === t("Enter")) FORGOTPASSWORD(toast, dispatch, getEmail, setEmailModalValidation, setGetEmail, setEmailModal)
  }

  // LANGUAGE CHANGE FUNCTION

  // useEffect(() => {
  //   if (chooselang == 1) {
  //     i18n.changeLanguage("en");
  //   }
  //   if (chooselang == 2) {
  //     i18n.changeLanguage("th");
  //   }
  //   localStorage.setItem('storeid', chooselang)
  // }, [chooselang]);




  useEffect(() => {
    // Check if there's a stored language in local storage
    const storedLanguage = localStorage.getItem('storeid');

    // If there's a stored language, use it
    if (storedLanguage) {
      if (storedLanguage == 1) {
        i18n.changeLanguage("en");
      } else if (storedLanguage == 2) {
        i18n.changeLanguage("th");
      }
    } else {
      // If no stored language, use the default logic based on chooselang
      if (chooselang == 1) {
        i18n.changeLanguage("en");
      }
      if (chooselang == 2) {
        i18n.changeLanguage("th");
      }


      // Store the chosen language in local storage
      localStorage.setItem('storeid', chooselang);
    }
  }, [chooselang]);

  const updateLanguage = (lang) => {
    if (lang === 1) {
      i18n.changeLanguage("en");
    } else if (lang === 2) {
      i18n.changeLanguage("th");
    }

    dispatch({
      type: "LG__CHANGE",
    });

    localStorage.setItem("storeid", lang);
    setChooseLang(lang);
  };

  // Define the error style inline
  const errorStyle = {
    // border: '2px solid rgba(255, 0, 0, 1) !important',
    // outline: '2px solid rgba(255, 0, 0, 1) !important',
    backgroundColor: 'rgba(255, 245, 245, 1)',
    border: '1px solid red !important'
  };

  // Mobile View singin, signup redirection
  const loginPage = currenturl + '/login';
  const handleSignUp = () => {
    window.location.href = 'https://register.jagota.com/?lang=' + chooselang;
  };
  const handleSignIn = () => {
    window.location.href = loginPage;
  };

  const hasErrors = errors?.username || errors?.password;

  // Determine left content based on whether it's reset password or login page
  const leftContent = id && tok ? (
    <>
      <div className='left_heading'>{t("Discover The World")}</div>
      <div className='left_info'>
        {t("We offer real solution for your daily operation")}
      </div>
    </>
  ) : (
    <>
      <div className='left_heading'>{t("Discover The World")}</div>
      <div className='left_info'>{t("Embark on a global")}</div>
    </>
  );


  // Right content remains the same
  const rightContent = (
    <>
      <div className={`right_content ${hasErrors ? 'error-present' : ''}`}>
        {id && tok ? (
          // Show Reset Password Component if id and tok are present
          <ResetPassword id={id} tok={tok} />
        ) : emailModal ? (
          <ForgotPassword onClose={() => setEmailModal(false)} />
          // <ResetPassword pageType="Forgot Password"></ResetPassword>
        ) : (
          <>
            <div className="login_right_content_mobile">
              <button type='button' className='signupbutton active' onClick={handleSignIn}>{t('SIGN_IN')}</button>
              <button type='button' className='signinbutton' onClick={handleSignUp}>{t('SIGN_UP')}</button>
            </div>
            <div className="right_content_row">
              <div className="welcome_text">{t("WELCOME_TO_JAGOTA_ONLINE")} </div>
              <div className="welcome_info">{t("ENJOY_SHOPPING_WITH_US")}</div>
            </div>
            <div className="right_content_row">
              <div className="signin_text">{t("SIGN_IN")}</div>
              <div className="new_user_text">{t("NEW_USER")}
                <span> &nbsp;
                  <a href={`https://register.jagota.com?lang=${chooselang}`}>
                    {t("CREATE_AN_ACCOUNT")}
                  </a>
                </span></div>
            </div>
            <div className="right_content_row">
              <div className={`right_username ${errors?.username ? 'error-input' : ''}`}
              // style={errors?.username ? errorStyle : {}}
              >
                <FormInput
                  name="username"
                  type="text"
                  autoComplete="username" 
                  style={errors?.username ? errorStyle : {}}
                  aria-labelledby="username"
                  label={t("Email address/ Mobile number")}
                  onChange={(e) => setusername(e.target.value)}
                  required />
              </div>
              {errors?.username && (
                <span className="loginpage__alert">{errors.username}</span>
              )}
              <div className={`right_password ${errors?.password ? 'error-input' : ''}`}
                style={errors?.password ? errorStyle : {}}>
                <PasswordField
                  label={t("Password")}
                  autoComplete="current-password" 
                  aria-labelledby="password"
                  style={errors?.password ? errorStyle : {}}
                  onChange={(e) => setpassword(e.target.value)}
                  name="password"
                  required
                />
              </div>
              {errors?.password && (
                <p className="loginpage__alert">{errors.password}</p>
              )}
              {/* {errors?.isChecked && (
                <div className="loginpage__alert">{errors.isChecked}</div>
              )} */}

            </div>
            <div className="right_content_col">
              <div className="right_checkbox">
                <Form.Group className="mb-3 form_check_box" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label={t("REMEMBER_ME")} />
                </Form.Group>
              </div>
              <div className="right_forget_pwd" >
                <button onClick={() => setEmailModal(true)}>{t("FORGOT_PASSWORD")}</button></div>
            </div>
            {/* <div className="right_content_col">
              <div className="checkbox-container">
                <input className="checkbox-input"
                  type="checkbox"
                  id="checkbox"
                  checked={isChecked}
                  onChange={handleCheckboxChange}
                />
                &nbsp;
                <label for="agree-checkbox" className="checkbox-label"> {t('BY_CONTINUING_I_AGREE_TO')}
                  <a href={privacyPolicy} target="_blank"
                    onclick={() => openPopup(privacyPolicy)}
                  ><span> {t('PRIVACY_POLICY')} </span></a>
                  {t('AND')}
                  <a href={termsConditions} target="_blank"
                    onclick={() => openPopup(termsConditions)}
                  ><span>{t('TERMS_&_CONDITIONS')}</span></a>.
                </label>
              </div>
            </div> */}
            <div className="right_content_row">
              <button type="submit" className="login__button" onClick={(e) => SubmitHandler(e)}>
                {t("SIGN_IN")}</button>
              {alert ? (<p className="loginpage__alert">{t("Invalid Username or Password")}</p>) : ("")}
            </div>
            <div className="right_content_row">
              <div className="login_footer">
                <div className='footer-line-1'> {t('COUNTRY/REGION')} :
                  <select value={selectedCountry} onChange={(e) => setSelectedCountry(e.target.value)}>
                    <option value="thailand">{t('THAILAND')}</option>
                    {/* <option value="english">English </option> */}
                  </select>
                </div>
                <button
                  className={chooselang === 2 ? "selected" : "footer-line-2"}
                  onClick={() => updateLanguage(2)}>ภาษาไทย</button>
                <button
                  className={chooselang === 1 ? "selected" : "footer-line-3"}
                  onClick={() => updateLanguage(1)}> {t('ENGLISH')} </button>
              </div>
            </div>
          </>)}
      </div>
    </>)


  const termsAndPrivacyPolicy =
    show ? (
      <Modal show={show} size="lg" className={`${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`} style={{ overflow: "hidden", maxHeight: '100vh' }
      } >
        <Modal.Header>
          <Modal.Title>{t("Terms and Conditions")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-grid align-items-left justify-content-left modalbody">
          <div className="overflow-scroll mainbody">
            <p className={`${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>{t("Our desire to do better and be the best drives the passion and commitment behind everything we do. Therefore it is our passion and commitment to excellence that has established JAGOTA as one of the most creative and innovative companies in Thailand today, bringing trend setting and bespoke food solutions.")}</p>
            <p className={`${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>{t("As a dedicated company, we are constantly evolving with new ideas, concepts, events and solutions that further drive the success of our customers wherever they are and whatever their business, Modern Trade, Food Service, Catering, E-commerce… In this fiercely competitive market, we are constantly learning, from our environment, customer’s needs, market trends… and growing in the process, to consistently delight")}</p>
          </div>
          <div className="termsmsg">
            <input type="checkbox" id="acceptcheck" onChange={(e) => termsCheck(e.target.checked)} />
            <p className={`${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>{t("I have read and agree terms and condition")}  <span style={{ fontSize: "20px" }}>*</span></p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => AGREETERMS(history, browserName, browserVersion)}
            id="regclosemodal"
            style={{ borderRadius: "unset", backgroundColor: "#37bfa7", border: "none" }}
            disabled={!checkingTerms}
            className={`${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
            {t("Agree")}
          </Button>
        </Modal.Footer>
      </Modal >
    ) : null


  return <LoginComponent leftContent={leftContent} rightContent={rightContent} termsAndPrivacyPolicy={termsAndPrivacyPolicy} />;
}

export default LoginPage;
