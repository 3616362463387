import React, { useEffect, useState } from 'react'
import './ProductInfo.scss'
import PDPImageCarousel from './PDPImageCarousel/PDPImageCarousel'
import PDPDetails from './PDPDetails/PDPDetails'
import { SIMILARPRODUCTS } from '../../../store/PDP/Action'
import { useStateValue } from '../../../store/state'
import Blocks from '../../Blocks/Blocks'
import { useTranslation } from 'react-i18next'
import ProductCardSmall from '../../ProductCardSmall/ProductCardSmall'
import { RequestQatation } from '../../CatalogComponents/CatalogPopOver/RequestQatation'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'

function ProductInfo({ pid, currentpdpdata, setrefreshpdp }) {
  const { t } = useTranslation()
  const [{ SIMILAR__PRODUCTS__DATA }, dispatch] = useStateValue()
  const [currentSku, setCurrentSku] = useState()
  const [refreshAPI, setRefreshAPI] = useState(false)
  const { page } = useParams();

  useEffect(() => {
    if (!currentpdpdata?.sku) return
    let USERID = localStorage.getItem('userid')
    let STOREID = localStorage.getItem('storeid')
    let COMPANYID = localStorage.getItem('companyid')
    SIMILARPRODUCTS(dispatch, currentpdpdata?.sku, USERID, STOREID, COMPANYID)
  }, [currentpdpdata, localStorage.getItem('storeid'), refreshAPI])

  return (
    <div className='INFO__PAGE'>
      <div className='productInfo'>

        <div className='productInfo__carousel'>
          {/* IMAGE CAROUSEL */}
          <PDPImageCarousel sku={currentSku} pid={pid} currentpdpdata={currentpdpdata} setrefreshpdp={setrefreshpdp} />
        </div>
        {window.innerWidth <= 912 && <>
          <h5>{currentpdpdata?.name}</h5>
          <div className='pdpdetails__quotesku'>
            {/* {mouseEnter ? <span className='plpDetails__quotemsg'>{t("Look for bulk purchase? Please request quotation here.")}</span> : null} */}
            <p>{t("SKU")} : {currentpdpdata?.sku}</p>
            {page !== "product" && currentpdpdata?.order_taking == "N" ?
              <RequestQatation
                className="pdpDetails__quotationButton"
                // setMouseEnter={setMouseEnter}
                units={currentpdpdata?.selling_unit}
                price={currentpdpdata?.selling_unit_price}
                pid={pid}
                status={currentpdpdata?.quote_status}
                setrefreshpdp={setrefreshpdp}
                old_quote_price={currentpdpdata?.old_quote_price}
              /> : <p>
                <span className='pdpDetails__unitprice--label'>{t("SIZE")}: {" "}</span>
                <span className='pdpDetails__unitprice--value' title={currentpdpdata?.pack_size}>{currentpdpdata?.pack_size}</span>
              </p>}
          </div>
        </>}

        {/* PRODUCT DETAILS */}
        <div className='productInfo__details'>
          <PDPDetails pid={pid} setCurrentSku={setCurrentSku} currentpdpdata={currentpdpdata} setrefreshpdp={setrefreshpdp} />
        </div>
      </div>

      {window.innerWidth > 912 && SIMILAR__PRODUCTS__DATA?.length ?
        <Blocks
          title={t("SIMILARTEXT")}
          like={SIMILAR__PRODUCTS__DATA}
          setRefreshAPI={setRefreshAPI}
          refreshAPI={refreshAPI} />
        :
        SIMILAR__PRODUCTS__DATA?.length ?
          <>
            <h5 className='INFO__TITLE'>{t("SIMILARTEXT")}</h5>
            <small className='INFO__TITLE1'>{t("CUSTOMERBOUGHT")}</small>
            <div className='PDPMOBILE__SIMILAR'>
              {SIMILAR__PRODUCTS__DATA?.map((data, i) => (
                <ProductCardSmall
                  key={i}
                  data={data}
                  setRefreshAPI={setRefreshAPI}
                  refreshAPI={refreshAPI}
                  title={t("SIMILARTEXT")}
                  page={"pdpmobile"}
                />
              ))}
            </div>
          </>
          : ""
      }
    </div>
  )
}

export default ProductInfo