import React, { useEffect, useRef, useState } from 'react'
import './ProductCard.scss'
import request from '../../request'
import { useTranslation } from 'react-i18next';
import favoriteactive from "../../assets/images/card/favorite.svg";
import favoriteinactive from "../../assets/images/card/BlackFavourite.svg";
import snowImg from "../../assets/images/snow-img.svg";
import quoteImg from "../../assets/images/quote-img.svg";
import quotationImg from "../../assets/images/Quotation.png";
import preOrderImg from "../../assets/images/PreOrder.png";
import chilledImg from "../../assets/images/Chilled.svg";
import bellImg from "../../assets/images/bell-icon-img.svg";
import ADDCART from "../../assets/images/card/ADDCART.svg"
import axios from 'axios';
import { useStateValue } from '../../store/state';
import moment from 'moment';
import Quote from '../../assets/images/card/Quote.svg'
import { toast } from 'react-toastify';
import { browserName, browserVersion } from 'react-device-detect';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function ProductCard(props) {
  const { image, pack_size, name, sku, selling_unit_price, entity_id, selling_unit, quote, favourite, options, is_in_stock, url_key, order_taking, category_id, storage } = props.data
  const { setRefreshAPI, title, refreshAPI, devicetype } = props

  const [currentID, setCurrentID] = useState(0)
  const preloadedImageRef = useRef(null);
  const { t } = useTranslation();
  const [{ cart }, dispatch] = useStateValue();

  useEffect(() => {
    if (image === undefined) return;

    const link = document.createElement('link');
    link.rel = 'preload';
    link.href = `${request.image}media/catalog/product${image}`;
    link.as = 'image';

    // Append the link element to the head
    document.head.appendChild(link);

    // Update the src of the preloadedImageRef once the resource is loaded
    link.onload = () => {
      preloadedImageRef.current.style.display = 'block';
      preloadedImageRef.current.src = link.href;
    };
  }, [image]);

  function formatToCurrency(price) {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const replacements = {
    "KG": t('KG'),
    "PC": t('PC'),
    "PK": t('PK'),
    "BG": t("BG"),
    "BK": t("BK"),
    "BT": t("BT"),
    "BX": t("BX"),
    "CA": t("CA"),
    "CP": t("CP"),
    "CT": t("CT"),
    "DR": t("DR"),
    "DZ": t("DZ"),
    "GL": t("GL"),
    "GM": t("GM"),
    "JR": t("JR"),
    "LT": t("LT"),
    "ML": t("ML"),
    "OZ": t("OZ"),
    "PA": t("PA"),
    "SC": t("SC"),
    "SH": t("SH"),
    "TI": t("TI"),
    "TR": t("TR"),
    "TU": t("TU"),
    "TY": t("TY"),
  };

  const updatedData = (item => {
    let updatedItem = item;
    Object.keys(replacements).forEach(key => {
      updatedItem = updatedItem.replace(key, replacements[key]);
    });

    return updatedItem;
  });

  const createlog = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      await axios({
        method: "post",
        url: request.newlog,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          data: {
            customer_id: localStorage.getItem("userid"),
            company_id: localStorage.getItem("companyid"),
            remote_ip: res.data.IPv4,
            user_agent: `${browserName} Version:${browserVersion}`,
            action: `${JSON.parse(localStorage.getItem("userdata")).firstname
              } added 1 ${name} in the cart`,
            action_type: "save",
            functioncall_url: "user/save/",
          },
        },
      });
      await axios({
        method: "post",
        url: request.createnotification,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: {
          data: {
            customer_id: localStorage.getItem("userid"),
            company_id: localStorage.getItem("companyid"),
            remote_ip: res.data.IPv4,
            user_agent: `${browserName} Version:${browserVersion}`,
            action_data: `${JSON.parse(localStorage.getItem("userdata")).firstname
              } added 1 ${name} in the cart`,
            action_type: "save",
            functioncall_url: "user/save/",
            status: 0,
            category: "notification"
          },
        },
      });
    } catch (e) {
      console.log(e)
      // toast.error(e.response?.data?.message);
    }
  };

  // Add to cart (single product)
  const addtocart = async () => {
    if (is_in_stock == "0") return
    let addressdata = [];
    try {
      const address = await axios({
        method: "get",
        url: `${request.getbranch}/${localStorage.getItem("userid")}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      addressdata.push(address.data);
    } catch (e) {
      console.log(e)
      // toast.error(e.response?.data?.message);
    }
    let defaultaddress;
    addressdata[0]?.find((data) => {
      if (data?.chk_default == "Y") {
        defaultaddress = data;
      }
    });
    const findsku = cart.find((c) => c.sku === sku);

    let date;
    try {
      const datechk = await axios({
        method: "get",
        url: `${request.erprequest}/products/${sku}/delivery-date`,
        headers: {
          token: `Bearer ${localStorage.getItem("erpToken")}`,
        },
      });
      date = datechk.data.data[0].DELIVER_DATE.slice(parseInt(defaultaddress?.leadtime_days));
    } catch (e) {
      console.log(e);
    }
    if (date) {
      try {
        const cartdata = await axios({
          method: "post",
          url: request.cartadd,
          data: {
            cartItem: {
              sku: sku,
              quote_id: localStorage.getItem("cartid"),
              qty: 1,
              price: options[0]?.price,
              extension_attributes: {
                unit: options[0]?.order_type,
              },
            },
            splitorder: {
              company_id: localStorage.getItem("companyid"),
              customer_id: localStorage.getItem("userid"),
              branch_id: defaultaddress?.address_id,
              product_id: entity_id,
              item_id: findsku?.item_id ? findsku?.item_id : 0,
              name: name,
              sku: sku,
              delivery_date: moment(date[0]?.DD).format("YYYY-MM-DD"),
            },
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        if (cartdata.data[0].errors == false) {
          toast.info("The Requested qty is not available");
        }
        if (cartdata.data[0].success) {
          createlog();
          toast.success(`${name} added to the cart Successfully`);
        }
        dispatch({
          type: "CART_STATUS",
        });
      } catch (e) {
        console.log(e)
      }
    }
  };

  // FAVOURITES HANDLER
  const addToFavHandler = () => {
    if (favourite) {
      deletefromFav()
    } else {
      addToFav()
    }
    setRefreshAPI(!refreshAPI)
  };

  // _ADD TO FAVOURITES API
  const addToFav = async () => {
    try {
      await axios({
        method: "post",
        url: request.addFav,
        data: {
          data: {
            customer_id: localStorage.getItem("userid"),
            product_id: entity_id,
            company_id: localStorage.getItem("companyid"),
          },
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch({
        type: "GENERAL__TRIGGER",
      });
      toast.success(`${name} ${t("FAVADDED")}`);
    } catch (e) {
      console.log(e);
      // toast.error(e.response?.data?.message);
    }
  };

  // DELETE FROM FAVOURITES API
  const deletefromFav = async () => {
    try {
      await axios({
        method: "delete",
        url: `${request.deletefav}/${entity_id}/${localStorage.getItem("userid")}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      dispatch({
        type: "GENERAL__TRIGGER",
      });
      toast.success(`${name} ${t("FAVREMOVED")}`);
    } catch (e) {
      console.log(e);
      // toast.error(e.response?.data?.message);
    }
  };

  const isFrozen = () => {
    return (storage || '').startsWith('FROZEN')
  }

  const isChilled = () => {
    return (storage || '').startsWith('CHILLED')
  }

  // truncateName namr for mobile and web
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 912);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 912);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const truncateName = (name) => {
    const maxLength = isMobile ? 40 : 60;
    return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
  };


  return (
    <div
      className={`PRODUCTCARD ${currentID === entity_id && "HOVERED__CARD"}`}
      onMouseEnter={() => setCurrentID(entity_id)}
      onMouseLeave={() => setCurrentID(0)}
    >
      {currentID === entity_id &&
        <img className='HOVERED__CARD__FAV'
          src={favourite ? favoriteactive : favoriteinactive}
          onClick={addToFavHandler}
          alt=""
        />
      }
      {order_taking == "Y" && currentID === entity_id ?
        <Link
          className='ORDERTAKING__CALENDAR HOVERED__CARD__ADDCART'
          to={`/pdp/${!quote ? "catalog" : "product"}/${order_taking == "Y" ? "ordertaking" : "stock"}/${category_id}/${entity_id}/${url_key}`}
        >
          <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none" >
            <g clip-path="url(#clip0_1972_38528)">
              <rect width="28" height="28" rx="14" fill="#37BFA7" />
              <path d="M10.1111 13.2H11.6667V14.8H10.1111V13.2ZM21 9.2V20.4C21 21.28 20.3 22 19.4444 22H8.55556C7.69222 22 7 21.28 7 20.4L7.00778 9.2C7.00778 8.32 7.69222 7.6 8.55556 7.6H9.33333V6H10.8889V7.6H17.1111V6H18.6667V7.6H19.4444C20.3 7.6 21 8.32 21 9.2ZM8.55556 10.8H19.4444V9.2H8.55556V10.8ZM19.4444 20.4V12.4H8.55556V20.4H19.4444ZM16.3333 14.8H17.8889V13.2H16.3333V14.8ZM13.2222 14.8H14.7778V13.2H13.2222V14.8Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_1972_38528">
                <rect width="28" height="28" rx="14" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </Link>
        : currentID === entity_id && <img onClick={addtocart} className={`HOVERED__CARD__ADDCART ${is_in_stock == "0" && "CART__NOSTOCK"}`} src={ADDCART} alt="" />
      }
      {devicetype === "mobile" ?
        <><Link to={`/pdp/${!quote ? "catalog" : "product"}/${order_taking == "Y" ? "ordertaking" : "stock"}/${category_id}/${entity_id}/${url_key}`}>
          <img ref={preloadedImageRef}
            id="preloadedImage"
            src=""
            alt=""
            height="100%"
            width="100%"
            className='PC__IMAGE'
          /></Link>
          <div className='product_btn_wrapper'>
            {order_taking === "Y" && <><span className='CARD__PREORDER'>Pre-Order</span></>}
            {isFrozen() && <img src={snowImg} className="snow-img" />}
            {isChilled() && <img src={chilledImg} className="snow-img" />}
          </div>
        </> : <div>
          <a href={`/pdp/${!quote ? "catalog" : "product"}/${order_taking == "Y" ? "ordertaking" : "stock"}/${category_id}/${entity_id}/${url_key}`}
            prefetch
            target="_blank">
            <img ref={preloadedImageRef}
              id="preloadedImage"
              src=""
              alt=""
              height="100%"
              width="100%"
              className='PC__IMAGE' />
          </a>
          <div className='product_btn_wrapper'>
            {order_taking === "Y" && <><span className='CARD__PREORDER'>Pre-Order</span></>}
            {isFrozen() && <img src={snowImg} className="snow-img" />}
            {isChilled() && <img src={chilledImg} className="snow-img" />}
          </div>
        </div>
      }
      <div className={`PC__CONTENT ${quote ? "QUOTE__PRODUCT" : "CATALOG__PRODUCT"}`}>
        {/* <h5 className='PC__NAME' title={name}>{name}</h5> */}
        <div className='PC__NAME' title={name}>
          {t(truncateName(name))}
        </div>
        <div className='PC__SKU'>{sku}</div>
        <div className='PC__PACKSIZE'>{t("SIZE")} {pack_size}</div>
        <div className='PC__QUOTATION__PRICE' >
          {quote && <img src={quotationImg} alt="" className='QUOTES' />}
          <div className='PC__PRICE'><span className='symbol'>฿</span> {formatToCurrency(parseFloat(selling_unit_price).toFixed(2))}/<span className='PC__UNIT'>{t(updatedData(selling_unit))}</span></div>
        </div>
        {/* <a href='#'>
          <span class="PC__UNAVAILABLE">UNAVAILABLE <img src={bellImg} alt="" height="100%" width="100%" className="PC__UNAVAILABLE_IMG" /></span>
        </a> */}
      </div>
    </div>
  )
}

export default ProductCard