import React from 'react'
import './Summary.scss'
import { useTranslation } from "react-i18next";
import { Link, useLocation } from 'react-router-dom/cjs/react-router-dom.min'

function Summary({ label1, value1, label2, value2, label3, value3, button, action, cartlength }) {
  const { t } = useTranslation();
  const location = useLocation()

  const FREESHIPPING_TEXT = location?.pathname == "/cart" && <p className='FREESHIPPING'>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_3607_24132)">
        <path d="M20 8H17V4H3C1.9 4 1 4.9 1 6V17H3C3 18.66 4.34 20 6 20C7.66 20 9 18.66 9 17H15C15 18.66 16.34 20 18 20C19.66 20 21 18.66 21 17H23V12L20 8ZM19.5 9.5L21.46 12H17V9.5H19.5ZM6 18C5.45 18 5 17.55 5 17C5 16.45 5.45 16 6 16C6.55 16 7 16.45 7 17C7 17.55 6.55 18 6 18ZM8.22 15C7.67 14.39 6.89 14 6 14C5.11 14 4.33 14.39 3.78 15H3V6H15V15H8.22ZM18 18C17.45 18 17 17.55 17 17C17 16.45 17.45 16 18 16C18.55 16 19 16.45 19 17C19 17.55 18.55 18 18 18Z" fill="#22A38C" />
      </g>
      <defs>
        <clipPath id="clip0_3607_24132">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <span className='FREESHIPPING__TEXT'>{t("CARTTEXTONE")}</span>
  </p>

  const RETURNREFUND = location?.pathname == "/cart" && <p className='RETURNREFUND'>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path d="M20.5 7.27734L12 11.9996M12 11.9996L3.49997 7.27734M12 11.9996L12 21.4996M21 16.0582V7.94104C21 7.5984 21 7.42708 20.9495 7.27428C20.9049 7.1391 20.8318 7.01502 20.7354 6.91033C20.6263 6.79199 20.4766 6.70879 20.177 6.54239L12.777 2.43128C12.4934 2.27372 12.3516 2.19494 12.2015 2.16406C12.0685 2.13672 11.9315 2.13672 11.7986 2.16406C11.6484 2.19494 11.5066 2.27372 11.223 2.43128L3.82297 6.54239C3.52345 6.70879 3.37369 6.792 3.26463 6.91033C3.16816 7.01502 3.09515 7.1391 3.05048 7.27428C3 7.42708 3 7.5984 3 7.94104V16.0582C3 16.4008 3 16.5721 3.05048 16.7249C3.09515 16.8601 3.16816 16.9842 3.26463 17.0889C3.37369 17.2072 3.52345 17.2904 3.82297 17.4568L11.223 21.5679C11.5066 21.7255 11.6484 21.8042 11.7986 21.8351C11.9315 21.8625 12.0685 21.8625 12.2015 21.8351C12.3516 21.8042 12.4934 21.7255 12.777 21.5679L20.177 17.4568C20.4766 17.2904 20.6263 17.2072 20.7354 17.0889C20.8318 16.9842 20.9049 16.8601 20.9495 16.7249C21 16.5721 21 16.4008 21 16.0582Z" stroke="#192739" stroke-opacity="0.94" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M16.5 9.5L7.5 4.5" stroke="#192739" stroke-opacity="0.94" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
    <span>
      <span>{t("CARTTEXTTWO")}</span>
      <Link to="/CMS/Return&Refund">{t("CARTTEXTTHREE")}</Link>
    </span>
  </p>

  const SUMMARYINFO = location?.pathname == "/cart" && <p className='SUMMARYINFO'>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0_3609_24162)">
        <path d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="#1C304A" fill-opacity="0.52" />
      </g>
      <defs>
        <clipPath id="clip0_3609_24162">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
    <span>
      {t("CARTTEXTFOUR")}
    </span>
  </p>

  function formatToCurrency(price) {
    return price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return (
    <>
      {/* {FREESHIPPING_TEXT} */}
      <p className='content'>
        {label1 && <span className='summary__label'>{t(label1)}:</span>}
        {value1 && <span className='summary__value'>฿ {formatToCurrency(parseFloat(value1).toFixed(2))}</span>}
      </p>
      <p className='content'>
        {label2 && <span className='summary__label'>{label2}:</span>}
        {value2 && <span className='summary__value'>฿ {formatToCurrency(parseFloat(value2).toFixed(2))}</span>}
      </p>
      <p className='content'>
        <span className='summary__label'>{t(label3)}:</span>
        {location.pathname == "/cart" ? <span className={`summary__value totalpay`}>฿ {formatToCurrency(parseFloat(value3).toFixed(2))}</span>
          : <span className='summary__value'>฿ {formatToCurrency(value3)}</span>
        }

      </p>
      {location.pathname == "/cart" ? <Link to={"/checkout"} className='summary__button'>
        {t(button)}
      </Link> :
        <a className='summary__button test' onClick={action}>
          {t(button)}
          {window.innerWidth <= 912 && <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <g clip-path="url(#clip0_2512_25360)">
              <path d="M10.5001 6L9.09009 7.41L13.6701 12L9.09009 16.59L10.5001 18L16.5001 12L10.5001 6Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_2512_25360">
                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
              </clipPath>
            </defs>
          </svg>}
        </a>
      }

      <div className='CART_INFO_TEXT'>
        {FREESHIPPING_TEXT}
        {RETURNREFUND}
        {SUMMARYINFO}
      </div>
    </>
  )
}

export default Summary