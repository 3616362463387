import axios from "axios";
import React, { useState, useEffect } from "react";
import { Modal } from "react-responsive-modal";
import { toast } from "react-toastify";
import request from "../../../request";
import CatalogSuccessPopUp from "../CatalogSuccessPopUp/CatalogSuccessPopUp";
import "./popover.scss";
import { useTranslation } from "react-i18next";
import quotaionIcon from '../../../assets/images/catalog/quotationIcon.svg'

export const RequestQatation = ({
  pid,
  units,
  price,
  status,
  setrefreshpdp,
  setMouseEnter,
  old_quote_price
}) => {
  const { t } = useTranslation();
  const [requestqty, setrequestqty] = useState();
  const [totalprice, settotalprice] = useState();
  const [quotePrice, setQuotePrice] = useState()
  const [showSuccess, setShowSuccess] = useState(false)
  const [remarks, setRemarks] = useState("")
  const decimalRegex = /^\d+(\.\d{0,2})?$/;
  const [open, setOpen] = useState(false)

  const updateqty = (value) => {
    // if(value <= 0) return
    if (decimalRegex.test(value) || value === '') {
      setrequestqty(value);
    }
  };

  useEffect(() => {
    // if(requestqty <= 0) return
    settotalprice(parseFloat(requestqty * quotePrice).toFixed(2));
  }, [price, requestqty]);

  const updateTotal = (value) => {
    // if(value <= 0) return
    if (decimalRegex.test(value) || value === '') {
      setQuotePrice(value)
      settotalprice(value * requestqty);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleClose = () => {
    setOpen(false)
    setShowSuccess(false)
    setrefreshpdp(true);
  };

  const newquotation = async () => {
    if (parseInt(totalprice) === 0 || totalprice == NaN || quotePrice == undefined) {
      return toast.info(
        `${t("QUOTATIONTEXT1")}`
      );
    }
    if (localStorage.getItem("userid") === null)
      return toast.warning(`${t("QUOTATIONTEXT2")}`);
    let customercode = JSON.parse(localStorage.getItem("userdata"));
    await axios({
      method: "post",
      url: request.createquotation,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: {
        data: {
          company_id: localStorage.getItem("companyid"),
          customer_id: localStorage.getItem("userid"),
          customer_code: customercode?.extension_attributes?.customer_code,
          product_id: pid,
          unit: units,
          rate: quotePrice,
          quantity: requestqty,
          total: totalprice,
          status: "Waiting for approval",
          remark: remarks
        },
      },
    });
    setShowSuccess(true)
  };

  if (status !== "" && status !== undefined) {
    return (
      <div className="reaquest__button_div position-relative">
        <p
          className="reaquest__button_orange"
          variant="success"
        >
          {status}
        </p>
      </div>
    );
  } else {
    return (
      <>
        <div className={`requestbutton__wrapper ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`} onMouseEnter={() => setMouseEnter(true)}
          onMouseLeave={() => setMouseEnter(false)}>
          <p className="reaquest__button" variant="success" onClick={() => setOpen(true)}>
            <img src={quotaionIcon} alt="" />
            {t("REQUESTQUOTATION")}
          </p>
        </div>
        <Modal
          open={open}
          onClose={() => setOpen(false)}
          center
          closeOnOverlayClick={false}
          classNames={{
            overlay: "customOverlay",
            modal: "REQUEST_QUOTATION_MODEL",
          }}
        >
          <form onSubmit={handleSubmit} className="quote__form">
            <div>
              <span className={`popover__close ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`} >
                <svg
                  onClick={() => setOpen(false)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="28"
                  height="28"
                  fill="currentColor"
                  className="bi bi-x"
                  viewBox="0 0 16 16"
                >
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
                {t("REQUESTQUOTATION")} <span>({t("STOCKPRODUCT")})</span>
              </span>
              <hr />
              <div className={`qatation_units d-flex ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <label>{t("QUOTATIONTEXT3")}</label>
                <div className="d-flex qatation_units_controls ">
                  <input
                    type="number"
                    pattern="[0-9]*" 
                    step="any"
                    className="form-control"
                    placeholder="0.00"
                    min="0"
                    required
                    value={requestqty}
                    onChange={(e) => updateqty(e.target.value)}
                  />
                  <p id="dropdownMenuButton">
                    {t(units)}
                  </p>
                </div>
              </div>
              <div className={`popover_price d-flex mt-2 ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <label className="price__label">
                  <span>{t("Whats your desired price?")}</span>
                  {old_quote_price && <span className="OLDQUOTE__PRICE">{t("QUOTATIONTEXT4")}: {old_quote_price}</span>}
                </label>
                <div className="d-flex popover_price qatation_units_controls">
                  <input
                    type="number"
                    pattern="[0-9]*" 
                    step="any"
                    className="form-control"
                    placeholder="0.00"
                    min="0"
                    value={quotePrice}
                    required
                    onChange={(e) => updateTotal(e.target.value)}
                  />
                  <p id="dropdownMenuButton">
                    {t(units)}
                  </p>
                </div>
              </div>
              <div className={`popover_price d-flex mt-2 ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
                <label className="price__label">{t("TOTAL")}</label>
                <div className="d-flex popover_price ">
                  <input
                    type="number"
                    pattern="[0-9]*" 
                    step="any"
                    className="form-control"
                    placeholder="0.00"
                    min="0"
                    value={parseFloat(totalprice).toFixed(2)}
                    required
                    disabled
                  />
                </div>
              </div>
              <div className="popover_price quotation__remarks">
                <label className="price__label">{t("REMARKS")}</label>
                <textarea onChange={e => setRemarks(e.target.value)} />
              </div>
              <span className="popover__msg">{t("QUOTATIONTEXT5")}</span>
            </div>
            <div className={`text_center quote__submit ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}`}>
              <button
                type="submit"
                className="reaquest__button SUBMIT__BUTTON"
                onClick={newquotation}
              >
                {t("SENDQUOTATION")}
              </button>
            </div>
          </form>
        </Modal>
        {showSuccess && <CatalogSuccessPopUp show={showSuccess} close={handleClose} />}
      </>
    );
  }
};
