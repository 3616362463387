import React, { useEffect, useMemo, useState } from 'react'
import './MoreInfo.scss'
import { useTranslation } from "react-i18next";

function MoreInfo({ data, seasonsData }) {
  const { t } = useTranslation();
  const tabs = [
    t('DESCRIPTION'), t('DETAILS'), t('BRANDSTORY')
  ]
  const [selectedTab, setSelectedTab] = useState()

  useEffect(() => {
    setSelectedTab(t('DESCRIPTION'))
  }, [data, t])

  // DESCRIPTION MEMO
  const descriptionsMemo = useMemo(() => {
    return selectedTab === t('DESCRIPTION') && <span>{data?.description}</span>
  }, [data, selectedTab])

  // DETAILEMEMO
  const detailMemo = useMemo(() => {
    return selectedTab === t('DETAILS') &&
      <>
        <div className='tabContent__details'>
          {window.innerWidth <= 912 ? <p>
            <span className='details--label'>{t("BRANDNAME")} :</span>
            <span className='details--value'>{data?.brand_name}</span>
          </p> : null}
          <p>
            <span className='details--label'>{t("PRODUCTTYPE")} :</span>
            <span className='details--value'>{data?.order_taking == "Y" ? t("PREORDERPRODUCT") : t("STOCKPRODUCT")}</span>
          </p>
          <p>
            <span className='details--label'>{t('VATTYPE')} :</span>
            <span className='details--value'>{data?.vat}{data?.vat ? "%" : "0%"}</span>
          </p>
          <p>
            <span className='details--label'>{t("COUNTRYORIGIN")} :</span>
            <span className='details--value'>{data?.country_of_manufacture}</span>
          </p>
          {window.innerWidth <= 912 ? <p>
            <span className='details--label'>{t("STOREMETHOD")} :</span>
            <span className='details--value'>{data?.storage}</span>
          </p> : null}
          <p>
            <span className='details--label'>{t("SHELLLIFE")} :</span>
            <span className='details--value'>{data?.maximum_shelf_life}</span>
          </p>
          <p>
            <span className='details--label'>{t("HALAL")} :</span>
            <span className='details--value'>{data?.halal_certificate == "N" ? t("NO") : t("YES")}</span>
          </p>
          <p>
            <span className='details--label'>{t("FOODSAFETY")} :
              <span>(GMP/HACCP/BRC/IFS/ISO)</span>
            </span>
            <span className='details--value'>{data?.food_safety_certificate == "N" ? t("NO") : t("YES")}</span>
          </p>
          <p>
            <span className='details--label'>{t("SEAFOODCERT")} :
              <span>(ASC/MSC)</span>
            </span>
            <span className='details--value'>{data?.seafood_certificate == "N" ? t("NO") : t("YES")}</span>
          </p>
        </div>
        {data?.order_taking == "Y" ? seasonsData : null}
      </>
  }, [data, selectedTab])

  // BRAND STORY MEMO
  const brandStoryMemo = useMemo(() => {
    return selectedTab === t('BRANDSTORY') && <span>{data?.story}</span>
  }, [data, selectedTab])

  const tabsMemo = useMemo(() => {
    return tabs?.map((T, i) => <h5 className={`${selectedTab === T && 'moreinfo__selectedTab'}`} onClick={() => setSelectedTab(T)} key={i}>{T}</h5>)
  }, [tabs])

  return (
    <div className='moreinfo'>
      <div className='moreinfo__tabs'>
        {tabsMemo}
      </div>
      <div className='moreinfo__tabContent'>
        {descriptionsMemo}
        {detailMemo}
        {brandStoryMemo}
      </div>
    </div>
  )
}

export default MoreInfo