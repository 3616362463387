import React, { useState, useEffect, lazy } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import LoginPage from "./pages/LoginPage/LoginPage";
import "./App.scss";
import { useStateValue } from "./store/state";
import { ToastContainer } from "react-toastify";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-multi-carousel/lib/styles.css";
import axios from "axios";
import request from "./request";
import ChangePassword from "./pages/changePassword/ChangePassword";
import PDPPage from "./pages/PDPPage/PDPPage";
import Loader from "./components/Loader/Loader";
import 'react-loading-skeleton/dist/skeleton.css'
import PaymentCollection from "./pages/Finance/PaymentCollection/PaymentCollection";
import Cart from "./pages/Cart/Cart";
import Success from "./pages/Finance/PaymentCollection/Success";
import CatalogPLP from "./pages/CatalogPLP/CatalogPLP";
import OrderPage from "./pages/OrderPage/OrderPage";
import OrderDetails from "./pages/OrderPage/OrderDetails/OrderDetails";
import MCatalog from "./pages/MCatalog/MCatalog";
import SearchSuggest from "./pages/SearchSuggest/SearchSuggest";
import MAccount from "./pages/MAccount/MAccount";
import MobileAddressForm from "./components/Account/DeliveryAddress/MobileAddressForm/MobileAddressForm";
import { useTranslation } from "react-i18next";
import CampaignProduct from "./pages/Productcard/CampaignProduct";
import Purchase from "./pages/Purchase/Purchase";
import MyFavourite from "./pages/MyFavourite/MyFavourite";
import MyReminder from "./pages/MyReminder/MyReminder";

const Header = lazy(() => import("./pages/Header/Header"));
const Footer = lazy(() => import("./pages/Footer/Footer"));
const Home = lazy(() => import("./pages/Home/Home"));
const CatalogPage = lazy(() => import("./pages/CatalogPage/CatalogPage"));
const RegisterSltB2B = lazy(() => import("./components/RegisterComponents/RegisterSltB2B/RegisterSltB2B"));
const CatalogCardDetails = lazy(() => import("./components/CatalogComponents/CatalogCardDetails/CatalogCardDetails"));
const Product = lazy(() => import("./pages/Product/Product"));
const Order = lazy(() => import("./pages/OrderModule/Order/Order"));
const OrderStatus = lazy(() => import("./pages/OrderModule/OrderStatus/OrderStatus"));
const OrdertoDelivery = lazy(() => import("./components/CartComponents/OrdertoDelivery/OrdertoDelivery"));
const OrderSuccess = lazy(() => import("./components/CartComponents/OrderSuccess/OrderSuccess"));
const Finance = lazy(() => import("./pages/Finance/Finance"));
const WishlistPage = lazy(() => import("./pages/WishlistPage/WishlistPage"));
const Account = lazy(() => import("./pages/AccountModule/Account/Account"));
const NotFound = lazy(() => import("./pages/NotFound/NotFound"));
const Blogs = lazy(() => import("./pages/Blogs/Blogs"));
const RegisterPage = lazy(() => import("./pages/RegisterPage/RegisterPage"));
const ScrollToButton = lazy(() => import("./components/ScrollButton/ScrollButton"));
const ScrolltoTop = lazy(() => import("./components/ScrollButton/ScrollToTop"));
const Invoice = lazy(() => import("./components/Order/Summary/Invoice/Invoice"));
const MobileReview = lazy(() => import("./pages/OrderModule/MobileReview/MobileReview"));
const Maintenance = lazy(() => import("./pages/Maintenance/Maintenance"));
const Complaint = lazy(() => import("./pages/OrderModule/Complaint/Complaint"));
const SearchPage = lazy(() => import("./pages/SearchPage/SearchPage"));
const CMSPage = lazy(() => import("./pages/CMSPage/CMSPage"))


function App() {
  const [token, setToken] = useState();
  const { i18n, t } = useTranslation();
  const [currentuser, setCurrentUser] = useState();
  const [maintenance, setMaintenance] = useState(false);
  const [{ loadertrigger, searchbar, FINANCE__INVOICE__DATA }, dispatch] =
    useStateValue();

  const clear = () => {
    dispatch({
      type: "CLEAR__SEARCH",
    })
  }

  useEffect(() => {
    const storedLanguage = localStorage.getItem('storeid')
    const lang = storedLanguage ? parseInt(storedLanguage, 10) : 2
    if (lang === 1) {
      i18n.changeLanguage("en");
    } else if (lang === 2) {
      i18n.changeLanguage("th");
    }
  }, [])

  // GET TOKEN FOR ERP
  useEffect(() => {
    const fetchData = async () => {
      if (localStorage.getItem("token") === null) return;

      try {
        const erptoken = await axios({
          method: "post",
          url: request.erplogin,
          data: {
            username: "jagota-iskula-b2b-team",
            password: "JIBT1234!@#$",
          },
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
          },
        });

        localStorage.setItem("erpToken", erptoken.data.data.token);
      } catch (e) {
        console.log(e);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const storedLanguage = localStorage.getItem('storeid')
    const lang = storedLanguage ? parseInt(storedLanguage, 10) : 2
    if (lang === 1) {
      i18n.changeLanguage("en");
    } else if (lang === 2) {
      i18n.changeLanguage("th");
    }
  }, [])


  // FUNCTION TO DISBALE CONSOLE LOGS IN SERVER
  function disableConsoleLogs() {
    if (window.location.origin !== 'http://localhost:3000') {
      console.log = function () { };
      console.warn = function () { };
      console.error = function () { };
      console.info = function () { };
      console.debug = function () { };
    }
  }

  useEffect(() => {
    disableConsoleLogs();

    // Cleanup function
    return () => {
      // Restore console methods to their original implementation
      console.log = console.__log__;
      console.warn = console.__warn__;
      console.error = console.__error__;
      console.info = console.__info__;
      console.debug = console.__debug__;
    };
  }, []);

  // MAINTENANCE API
  useEffect(() => {
    const fetchMaintenanceData = async () => {

      try {
     
       const maintresult = await axios.get(request.maintenance)
        dispatch({
          type: 'SET_DOWNTIME_INFO',
          payload: maintresult.data[0]
        });
        setMaintenance(!maintresult.data[0].success)
      } catch (e) {
      setMaintenance(false);
        dispatch({
          type: 'SET_DOWNTIME_INFO',
          payload: {success: true, data: {}}
        });
      }
    };

    fetchMaintenanceData();
    const interval = setInterval(() => {
      fetchMaintenanceData();
    }, 5 * 60 * 1000); // 5 minutes in milliseconds
  
    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [window.location.href, dispatch]);
  
 
  // SAVING USERINFO AFTER LOGGEDIN
  useEffect(() => {
    setToken(localStorage.getItem("token"));
    dispatch({
      type: "SET_USER",
      user: localStorage.getItem("user"),
    });
    setCurrentUser(localStorage.getItem("username"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("token")]);

  return (
    <div className={`app ${localStorage.getItem('storeid') == 1 ? 'englang' : 'thailang'}
      ${FINANCE__INVOICE__DATA?.length > 0 && "IFRAME"}
    `}>
      {searchbar ? <span onClick={clear} className="bg__trans"></span> : null}
      {maintenance ? (
        <Router>
          <Switch>
            <Route path="/">
              <Header isMaintanceMode />
              <Maintenance />
            </Route>
          </Switch>
        </Router>
      ) : (
        <Router>
          <ScrolltoTop />
          <Switch>
            <Route path="/login" component={LoginPage} exact />
            <Route path="/resetpassword/:id/:tok" component={LoginPage} exact />
            <Route path="/register" component={RegisterPage} exact />

            {/* HomePage */}
            <Route path="/" exact>
              <Header />
              <Home token={token} />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* ChangePassword */}
            <Route path="/changepassword" exact>
              <Header />
              <ChangePassword token={token} />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* Blogs */}
            <Route path="/blogs" exact>
              <Header />
              <Blogs />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            <Route path="/MCatalog" exact>
              <MCatalog />
              <Footer />
            </Route>

            <Route path="/MCatalog/:type" exact>
              <MCatalog />
              <Footer />
            </Route>

            <Route path="/MAccount" exact>
              <MAccount />
              <Footer />
            </Route>

            {/* catalog Home*/}
            <Route path="/catalog/:CATAGORYNAME/:CATAGORYID" exact>
              <Header />
              <CatalogPage />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* catalog plp */}
            <Route path="/catalog/:lvl3name/:lvl3id/:lvl2name/:lvl2id" exact>
              <Header />
              <CatalogPLP />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
              <Footer />
            </Route>

            {/* catalog pdp page */}
            <Route path="/:page/cdetails/:cid?/:pid/:sku" exact>
              <Header />
              <CatalogCardDetails />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
              <Footer />
            </Route>

            {/* product-plp lvl1*/}
            <Route path="/myproduct" exact>
              <Header />
              <Product />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>
            <Route path="/mypurchase" exact>
              <Header />
              <Purchase />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>
            <Route path="/myreminder" exact>
              <Header />
              <MyReminder />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>
            <Route path="/myfavourite" exact>
              <Header />
              <MyFavourite />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>
            {/* New Catalog PDP */}
            <Route path="/pdp/:page/:productType/:cid/:pid/:sku">
              <Header />
              <PDPPage />
              <Footer />
            </Route>
            
            {/* New Campaingn Page */}
            <Route path="/campaign/household/:Id/:STOREID/:categoryId" exact>
              <Header />
              <CampaignProduct />
              <Footer />
            </Route>
            <Route path="/campaign/coffeeshop/:Id/:STOREID/:categoryId" exact>
              <Header />
              <CampaignProduct />
              <Footer />
            </Route>
            <Route path="/campaign/sme/:Id/:STOREID/:categoryId" exact>
              <Header />
              <CampaignProduct />
              <Footer />
            </Route>

            {/* New Product PDP */}
            <Route path="/product/:productType/:cid/:pid/:sku">
              <Header />
              <PDPPage />
              <Footer />
            </Route>


            {/* searchpage */}
            <Route path="/searchpage/:searchstring/:subCat?/:id" exact>
              <Header />
              <SearchPage />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* {window.innerWidth <= 912 && */}
              <Route path="/searchSuggest" exact>
                <SearchSuggest />
                <Footer />
                {/* {window.innerWidth > 912 ? <ScrollToButton /> : ""} */}
              </Route>
            {/* } */}

            {/* product-plp lvl2 */}
            <Route path="/product/:categoryType/:subCategoryType" exact>
              <Header />
              <Product />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* orderpage */}
            <Route path="/myorder" exact>
              <Header />
              <OrderPage />
              {/* <Order token={token} /> */}
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* Order - Complaint */}
            <Route path="/myorder/complaint/:complaintid" exact>
              <Header />
              <Complaint token={token} />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* orderstatus */}
            <Route path="/myorder/:orderid" exact>
              <Header />
              {/* <OrderStatus token={token} /> */}
              <OrderDetails />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* order mobile create review */}
            <Route path="/review/:pname/:pid" exact>
              <Header />
              <MobileReview token={token} />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* Invoice */}
            <Route path="/invoice/" exact>
              <Invoice />
            </Route>

            {/* Finance Page */}
            <Route path="/myfinance/" exact>
              <Header />
              <Finance token={token} />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* Finance - PaymentCollection */}
            <Route path="/myfinance/paymentcollection" exact>
              <Header />
              <PaymentCollection token={token} />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* Finance - Payment Success */}
            <Route path="/myfinance/success" exact>
              <Header />
              <Success />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* Account Page */}

            <Route path="/myaccount/:type" exact>
              <Header />
              <Account />
              {/* <Footer /> */}
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            {/* Success Page */}
            <Route path="/myaccount/:success" exact>
              <Header />
              <Account token={token} />
              <Footer />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
            </Route>

            <Route path="/login" component={LoginPage} exact />
            {/* register */}
            <Route path="/register" component={RegisterSltB2B} exact />


            <Route path="/checkout">
              <Header />
              <OrdertoDelivery />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
              <Footer />
            </Route>

            <Route path="/cart">
              <Header />
              <Cart />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
              <Footer />
            </Route>

            <Route path="/cartPage/success">
              <Header />
              <OrderSuccess />
              <Footer />
            </Route>

            <Route path="/wishlistfav">
              <Header />
              <WishlistPage token={token} />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
              <Footer />
            </Route>
            <Route path="/CMS/:page">
              {token ? <Header /> : null}
              <CMSPage />
              {window.innerWidth > 912 ? <ScrollToButton /> : ""}
              {token ? <Footer /> : null}
            </Route>
            {/* <Route path="/sitemap" component={Sitemap} /> */}

            <Route>
              <Header />
              <NotFound />
              <Footer />
            </Route>

          </Switch>
          <ToastContainer />
        </Router>
      )}
      {loadertrigger && <Loader />}
    </div>
  );
}

export default App;
