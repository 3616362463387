import React, { useEffect, useState } from 'react'
import './OrderSummary.scss'
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import { REORDER } from '../../../store/Order/Action';
import { useStateValue } from '../../../store/state';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toast } from "react-toastify";

function OrderSummary({ data }) {
  const { t } = useTranslation()
  const [{ }, dispatch] = useStateValue();
  const history = useHistory();
  const { orderid } = useParams();
  const [vatLength, setVatLength] = useState(0)
  function formatToCurrency(price) {
    var outstanding = price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return outstanding?.replace(/\.(\d{1,2}).*$/, '.$1')
  }
  useEffect(() => {
    let vatdata = data?.productList?.filter(el => el?.taxAmount > 0)
    setVatLength(vatdata)
  }, [data])

  return (
    <div className='ORDERSUMMARY'>
      <h5>{t("Order Summary")}</h5>
      <div className='ORDERSUMMARY__TOP'>
        <p>
          <span>{t("Total Price")}</span>
          <span>฿ {formatToCurrency(parseFloat(data?.totalPrice).toFixed(2))}</span>
        </p>
        <p>
          <span>{t("Total Product")}</span>
          <span>{data?.totalQty}</span>
        </p>
      </div>
      <hr />
      <div className='ORDERSUMMARY__BOTTOM'>
        <h5>{t("Check Out Summary")}</h5>
        <Accordion alwaysOpen defaultActiveKey="0">
          <Accordion.Item>
            <Accordion.Header>
              {t("Product Selected")}{" "}({data?.productList?.length})
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {data?.productList?.map((childdata, index) => (
                  <li
                    eventKey={index}
                    key={childdata?.id}
                    title={t(childdata?.productName)}
                  >
                    <span>{index + 1}</span>
                    <span className='SUMMARY__NAME'>{t(childdata?.productName)}</span>
                    <span className='SUMMARY__VAT'>{childdata?.taxPercentage}%</span>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <p>
          <span>{t("Subtotal")}</span>
          <span>฿ {formatToCurrency(parseFloat(data?.subtotalPrice).toFixed(2))}</span>
        </p>
        <Accordion alwaysOpen defaultActiveKey="0">
          <Accordion.Item>
            <Accordion.Header>
              {t("VAT")} 7%{" "}({vatLength?.length})
            </Accordion.Header>
            <Accordion.Body>
              <ul>
                {data?.productList?.map((childdata, index) => (
                  <li
                    eventKey={index}
                    key={childdata?.id}
                    title={t(childdata?.productName)}
                  >
                    <span>{index + 1}</span>
                    <span className='SUMMARY__NAME'>{t(childdata?.productName)}</span>
                    <span className='SUMMARY__VAT'>฿ {formatToCurrency(data?.totalTax)}</span>
                  </li>
                ))}
              </ul>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <p className='ORDERSUMMARY__TOTAL'>
          <span>{t("Total")}</span>
          <span>฿ {formatToCurrency(parseFloat(data?.totalPrice).toFixed(2))}</span>
        </p>
      </div>
      <button className='ORDERSUMMARY__REORDER' onClick={() => REORDER(dispatch, history, orderid, toast)}>{t("Reorder")}</button>
    </div>
  )
}

export default OrderSummary