import axios from "axios";
import request from "../../request";

export const MEGAMENU = (dispatch) => {
    let TOKEN = localStorage.getItem('token');

    async function fetchMegaMenuFromApi() {
        try {
            const data = await axios({
                method: 'get',
                url: request.megamenu,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            dispatch({
                type: "MEGAMENU__CONTENT",
                payload: data?.data
            });
            localStorage.setItem('megamenuData', JSON.stringify(data?.data));
            localStorage.setItem('lastFetchTime', new Date().getTime());
        } catch (e) {
            console.log(e);
        }
    }

    function fetchMegaMenuFromLocalStorage() {
        const megamenuData = localStorage.getItem('megamenuData');
        if (megamenuData) {
            dispatch({
                type: "MEGAMENU__CONTENT",
                payload: JSON.parse(megamenuData)
            });
        } else {
            fetchMegaMenuFromApi();
        }
    }

    // Check if data exists in local storage
    fetchMegaMenuFromLocalStorage();

    // Check if 2 hours have passed, if so, fetch fresh data from API
    const lastFetchTime = localStorage.getItem('lastFetchTime');
    if (lastFetchTime && (new Date().getTime() - parseInt(lastFetchTime, 10) >= 2 * 60 * 60 * 1000)) {
        // Clear local storage
        localStorage.removeItem('megamenuData');
        localStorage.removeItem('lastFetchTime');
        // Fetch fresh data from APi
        fetchMegaMenuFromApi();
    }
};

// Debounce function
const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func.apply(this, args), delay);
    };
};

const debouncedSearchText = debounce(async (dispatch, storeid, userid, searchproduct) => {
    try {
        const data = await axios({
            method: "post",
            url: `${request.search}/?query_text=${searchproduct}&category_id=0&store_id=${storeid}&limit_terms=${userid}`,
        });
        dispatch({
            type: "SEARCH__TEXT",
            payload: data.data
        });
        dispatch({
            type: "SEARCHBAR__CLOSEOPEN",
            status: data?.data[0]?.products
        });
    } catch (e) {
        console.log(e);
    }
}, 1000);

export const SEARCHTEXT = (dispatch, storeid, userid, searchproduct, location, history) => {
    if (searchproduct.length > 3) {
        debouncedSearchText(dispatch, storeid, userid, searchproduct);
    } else {
        dispatch({
            type: "SEARCH__TEXT",
            payload: [] 
        });
    }
}
export const SEARCHRESULTSGLOBAL = (dispatch, setLoader, searchKey) => {
    const customer_id = localStorage.getItem("userid");
    async function searchresults() {
      dispatch({
        type: "SEARCH_STRING_RESULT_GLOBAL",
        payload: []
      })
  
      try {
        const data = await axios({
          method: "post",
          url: `${request.SEARCHPAGEAPIGOBAL}/${customer_id}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            customer_id: customer_id, 
            searchkey: searchKey 
        }
      
        });
        dispatch({
          type: "SEARCH_STRING_RESULT_GLOBAL",
          payload: data?.data[0].data
        })
      } catch (e) {
        console.log(e)
      }
    }
    searchresults()
  }
  export const SETSEARCHTERMS = (dispatch, customerId, searchKey) => {
    async function setSearchTerms() {
        try {
            await axios({
                method: "post",
                url: `${request.SEARCHSETTERMS}`,
                data: {
                    customer_id: customerId,
                    searchkey: searchKey
                }
            });
            
            dispatch({
                type: "ADD_SEARCH_TERM", 
                payload: { 
                    term: searchKey, // Send searchKey in the payload
                    customer_id: customerId // Include customerId if needed
                } 
            });
            console.log(searchKey, "searchkey");
            console.log(customerId, "payload");
         
        } catch (e) {
            console.log(e);
        }
    }
    setSearchTerms();
}
// export const HANDLESEARCHINPUT = (dispatch, customerId, searchKey) => {
//     // Call SETSEARCHTERMS on input focus or click
//     SETSEARCHTERMS(dispatch, customerId, searchKey);
    
//     // Call SEARCHRESULTSGLOBAL to fetch recent search results
//     SEARCHRESULTSGLOBAL(dispatch, customerId, searchKey);
// }
export const NOTIFICATION = (dispatch, USERID, TOKEN) => {
    async function notification() {
        try {
            const data = await axios({
                method: "get",
                url: `${request.getnotification}/${USERID}`,
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            });
            var sortedData = data.data
                .sort((a, b) => {
                    return (
                        new Date(a.created_at).getTime() -
                        new Date(b.created_at).getTime()
                    );
                })
                .reverse();
            dispatch({
                type: "NOTIFICATION__CONTENT",
                payload: sortedData
            })
        } catch (e) {
            console.log(e)
        }
    }
    notification()
}

export const READNOTIFICATION = (dispatch, value) => {
    async function readnotification() {
        try {
            await axios({
                method: "post",
                url: `${request.readnotification}/${value.id}`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            });
            dispatch({
                type: "SHOW__HIDE__NOTIFICATION"
            })
        } catch (e) {
            console.log(e)
        }
    }
    readnotification()
}

export const MARQUEE = (setMarqueeText) => {
    async function marquee() {
        try {
            const data = await axios({
                method: "get",
                url: request.MARQUEECONTENT
            })
            setMarqueeText(data?.data)
        } catch (e) {
            console.log(e)
        }
    }
    marquee()
}