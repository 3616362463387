import axios from "axios";
import request from "../../request";

export const PDPPAGE = (dispatch, page, pid) => {
    if (page === 'product') {
        async function fetchData() {
            try {
                const catalogpdpdetails = await axios({
                    method: "get",
                    url: `${request.getproductpdp}/${localStorage.getItem("companyid")}/${pid}/${localStorage.getItem("storeid") != "undefined" ? localStorage.getItem("storeid") : 1}`,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                dispatch({
                    type: "PDP__DATA",
                    payload: catalogpdpdetails.data[0]
                })
            } catch (e) {
                console.log(e)
                // toast.error(e.response?.data?.message);
            }
        }
        fetchData();
    } else {
        async function fetchData() {
            let cid = localStorage.getItem("companyid");
            try {
                const catalogpdpdetails = await axios({
                    method: "get",
                    url: `${request.getcatalogpdp}/${pid}/${localStorage.getItem("storeid") != "undefined" ? localStorage.getItem("storeid") : 1}/${cid ? cid : 0}/${localStorage.getItem('userid')}`,
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                dispatch({
                    type: "PDP__DATA",
                    payload: catalogpdpdetails?.data[0]
                })
            } catch (e) {
                console.log("error : ",e)
                // toast.error(e.response?.data?.message);
            }
        }
        fetchData();
    }
}

export const ORDERINGDATES = (dispatch, sku) => {
  if(sku == undefined) return
    async function orderingdates() {
        try {
            const data = await axios({
                method: "get",
                url: `${request?.ERPPDPORDERPERIOD}PRODUCT_CODE=${sku}&STATUS=%25`
            })

           // Define a custom sorting function
            const customSort = (a, b) => {
                // If STATUS is the same, compare by DELIVER_DATE
                if (a.DELIVER_DATE < b.DELIVER_DATE) {
                    return -1;
                }
                if (a.DELIVER_DATE > b.DELIVER_DATE) {
                    return 1;
                }

                // If both STATUS and DELIVER_DATE are the same, no change in order
                return 0;
            };
                
            // Sort the data using the custom sorting function
            data?.data?.data?.sort(customSort);

            dispatch({
                type: "ORDERTAKING__DATES",
                payload: data?.data?.data?.sort((a, b) => b.STATUS.localeCompare(a.STATUS))
            })
        } catch (e) {
            console.log(e)
        }
    }
    orderingdates()
}

export const DateOrderDelivery = (sku) => {
  if (sku === undefined) return;
  async function orderingdates() {
    try {
      const data = await axios({
        method: "get",
        url: `${request?.ERPPDPORDERPERIOD}PRODUCT_CODE=${sku}&STATUS=%25`,
      });

      // Define a custom sorting function
      const customSort = (a, b) => {
        // If STATUS is the same, compare by DELIVER_DATE
        if (a.DELIVER_DATE < b.DELIVER_DATE) {
          return -1;
        }
        if (a.DELIVER_DATE > b.DELIVER_DATE) {
          return 1;
        }

        // If both STATUS and DELIVER_DATE are the same, no change in order
        return 0;
      };

      // Sort the data using the custom sorting function
      const sortedData = data?.data?.data?.sort(customSort);

      return sortedData?.sort((a, b) => b.STATUS.localeCompare(a.STATUS));
    } catch (e) {
      return null; // Return null or handle error appropriately
    }
  }
  return orderingdates();
};

// List Delivery Date for stock product API call-s
export const DateOrderDeliveryForStockProduct = (sku) => {
    async function orderingdatesForStockProduct() {
        try {
            const datechk = await axios({
                method: "get",
                url: `${request.erprequest}/products/${sku}/delivery-date`,
                headers: {
                    token: `Bearer ${localStorage.getItem("erpToken")}`,
                },
            });
            return datechk.data.data[0].DELIVER_DATE
        } catch (e) {
            return null; // Return null or handle error appropriately
        }
    }
    return orderingdatesForStockProduct()
}
export const SEASONS = (dispatch, sku) => {
    async function seasons() {
        try {
            const data = await axios({
                method: 'get',
                url: `${request.ERPPDPSEASONS}PRODUCT_CODE=${sku}`
            })
            dispatch({
                type: "SEASONS__DATA",
                payload: data?.data?.data
            })
        } catch (e) {
            console.log(e)
        }
    }
    seasons()
}

export const PDPIMAGES = (dispatch, sku) => {
    async function pdpimages() {
        try {
            const data = await axios({
                method: "get",
                url: `${request.erprequest}/products/product-marketing-data?PRODUCT_CODE=${sku}`,
                headers: {
                    token: `Bearer ${localStorage.getItem("erpToken")}`,
                },
            })
            dispatch({
                type: 'PDP__IMAGES',
                payload: data.data.data[0]?.attachments?.images
            })
            data.data.data[0]?.attachments?.images?.map((value) => {
                const link = document.createElement('link');
                link.rel = 'preload';
                link.href = `${value.URL}`;
                link.as = 'image';
                document.head.appendChild(link);
            })
        } catch (e) {
            console.log(e)
        }
    }
    pdpimages();
}

export const ADDFAV = (dispatch, pid, setView, toast, message) => {
    let USERID = localStorage.getItem('userid')
    let COMPANYID = localStorage.getItem("companyid")
    let TOKEN = localStorage.getItem("token")
    async function addfav() {
        try {
            const data = await axios({
                method: "post",
                url: request.addFav,
                data: {
                    data: {
                        customer_id: USERID,
                        product_id: pid,
                        company_id: COMPANYID,
                    },
                },
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            if (data?.data[0]?.success) {
                setView(true);
                dispatch({
                    type: "GENERAL__TRIGGER",
                });
                toast.success(message);
            } else {
                toast.info(data?.data[0]?.message)
            }
        } catch (e) {
            console.log(e)
        }
    }
    addfav()
}

export const DELETEFAV = (dispatch, pid, view, setView, toast, message, setrefreshpdp) => {
    async function deletefav() {
        try {
            const data = await axios({
                method: "delete",
                url: `${request.deletefav}/${pid}/${localStorage.getItem("userid")}`,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
            })
            if (data?.data[0]?.success) {
                setView(!view);
                dispatch({
                    type: "GENERAL__TRIGGER",
                });
                toast.success(message);
                setrefreshpdp(true)
            } else {
                toast.info(data?.data[0]?.message)
            }

        } catch (e) {
            console.log(e)
        }
    }
    deletefav()
}

export const ADDTOWISH = (dispatch, pid, setFav, message, toast) => {
    const USERID = localStorage.getItem("userid");
    const TOKEN = localStorage.getItem("token");
    async function addtowish() {
        try {
            const data = await axios({
                method: "post",
                url: request.addWishlist,
                data: {
                    customerId: parseInt(USERID),
                    productId: parseInt(pid),
                },
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })

            if (data?.data[0]?.success) {
                setFav(true);

                dispatch({
                    type: "GENERAL__TRIGGER",
                });
                toast.success(message)
            } else {
                toast.info(data?.data[0]?.message)
            }
        } catch (e) {
            console.log(e)
        }
    }
    addtowish()
}

export const DELETEFROMWISH = (dispatch, pid, setFav, message, setrefreshpdp, fav, toast) => {
    const USERID = localStorage.getItem("userid");
    const TOKEN = localStorage.getItem("token");
    async function deletefromwish() {
        try {
            const data = await axios({
                method: "post",
                url: request.deletewishlist,
                data: {
                    customerId: USERID,
                    productId: pid,
                },
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            if (data?.data[0]?.success) {
                setFav(!fav);
                dispatch({
                    type: "GENERAL__TRIGGER",
                });
                setrefreshpdp(true)
                toast.success(message);
            } else {
                toast.info(data?.data[0]?.message)
            }
        } catch (e) {
            console.log(e)
        }
    }
    deletefromwish()
}

export const SIMILARPRODUCTS = (dispatch, SKU, USERID, STOREID, COMPANYID) => {
    const TOKEN = localStorage.getItem("token");
    async function similarproducts() {
        try {
            const data = await axios({
                method: "post",
                url: request.SIMILARPRODUCTS,
                data: {
                    "companyId": COMPANYID,
                    "customerId": USERID,
                    "storeId": STOREID,
                    "sku": SKU
                    // "sku": "MTBBRT30720339"
                },
                headers: {
                    "Authorization": `Bearer ${TOKEN}`,
                },
            })
            dispatch({
                type: "SIMILAR__PRODUCTS",
                payload: data?.data[0]?.products
            })
        } catch (e) {
            console.log(e)
        }
    }
    similarproducts()
}

